import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Scope } from '@unform/core';
import { useLocation } from 'react-router-dom';
// import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import {
  MdDone,
  MdClose,
  MdCrop,
  MdDelete,
  MdSelectAll,
  MdStayPrimaryLandscape,
  MdStayPrimaryPortrait,
  MdTableRows,
  MdOutlineAddPhotoAlternate,
  MdSchema,
  MdAutorenew,
  MdPictureAsPdf,
  // MdZoomIn,
  // MdZoomOut,
  // MdYoutubeSearchedFor,
  // MdCancel,
} from 'react-icons/md';
import { RxDividerVertical } from 'react-icons/rx';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import { getNivel, setNivel } from '../../../lib/asyncUtils';
import { throttle } from '../../../lib/inputUtils';
import {
  opPaginaCatalogoSituacao,
  opPaginaCatalogoOrientacao,
} from '../../../lib/const';

import TitleBar from '../../../components/TitleBar';
import Resizeble from '../../../components/Resizeble';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/EditorAI/Pagination';

import { Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';
import AsyncCreatableSelectInput from '../../../components/Form/Input/AsyncCreatableSelect';

import { EditorContainer } from '../../../components/Container';

import { Container, Wrapper, ToolBar, Image } from './styles';
import { Action } from '../styles';

const schema = Yup.object().shape({
  descricao: Yup.string().required('O campo "Decrição" deve ser preenchido'),
  nota: Yup.string().max(
    2000,
    'O campo "Nota" ultrapassou o limite de 2000 caracteres'
  ),
});

function EditorEditarAI() {
  const location = useLocation();
  const { id, paginaInicial = 0 } = location.state;

  const formRef = useRef();
  const resizeRef = useRef();

  // const zoomInRef = useRef(HTMLButtonElement);
  // const zoomOutRef = useRef(HTMLButtonElement);
  // const zoomResetRef = useRef(HTMLButtonElement);

  const [catalogo, setCatalogo] = useState(null);
  const [paginaCatalogo, setPaginaCatalogo] = useState(null);
  const [nivelClassificacao, setNivelClassificacao] = useState([]);
  // const [passosTransformacao, setPassosTransformacao] = useState([]);
  const [loading, setLoading] = useState(false);

  // const [zoom, setZoom] = useState(false);
  // const [zoomCanMove, setZoomCanMove] = useState(true);
  // const [loading, setLoading] = useState(false);

  /** controle de páginas */
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPage, setTotalPage] = useState(0);

  const carregaPagina = useCallback(async () => {
    if (catalogo) {
      /** limpa controles */
      if (resizeRef.current) {
        resizeRef.current.clearSelection();
      }

      // setPassosTransformacao([]);
      setPaginaCatalogo(null);

      /** pega id da página  */
      const pagina = catalogo.paginas[currentPage - 1];
      if (pagina) {
        setLoading(true);
        try {
          /** carrega itens da página */
          const response = await api.get(
            `editor-editar/${id}/pagina/${pagina}`
          );

          /** formata níveis */
          const nivelValue = response.data.nivelPaginaCatalogo.map((nivel) => ({
            name: `nivel_${nivel.nivel}`,
            value: {
              value: nivel.idNivel,
              label: nivel.descricao
                ? `${nivel.codigo} - ${nivel.descricao}`
                : `${nivel.codigo}`,
            },
          }));

          setPaginaCatalogo({
            ...response.data,
            urlImagem: `${response.data.urlImagem}?v=${Date.now()}`,
          });

          /** carrega informações da página */
          if (formRef.current) {
            formRef.current.reset();

            nivelValue.forEach((nivel) => {
              formRef.current.setFieldValue(
                `niveis.${nivel.name}`,
                nivel.value
              );
            });
            formRef.current.setFieldValue(
              'caminhoImagem',
              response.data.caminhoImagem
            );
            formRef.current.setFieldValue(
              'orientacaoImagem',
              response.data.orientacaoImagem
            );
            formRef.current.setFieldValue('descricao', response.data.descricao);
            formRef.current.setFieldValue('nota', response.data.nota);
          }
        } finally {
          setLoading(false);
        }
      }
    }
  }, [catalogo, id, currentPage]);

  useEffect(() => {
    /** informações do catálogo */
    api.get(`editor-editar/${id}`).then((response) => {
      const paginas = response.data.paginaCatalogo;

      setNivelClassificacao(
        response.data.nivelClassificacoes.map((classificacao) => ({
          idCatalogo: id,
          nivel: classificacao.nivel,
          descricao: classificacao.descricao,
        }))
      );

      setCatalogo({
        ...response.data,
        paginas: response.data.paginaCatalogo.map((pagina) => pagina.idPagina),
      });

      setTotalPage(response.data.total);

      setCurrentPage(() => {
        /** index da última página concluída do catálogo */
        const lastIndex = Math.max(
          ...paginas.reduce((acumuladoPagina, atualPagina, index) => {
            // adiciona primeira página
            if (
              index === 0 ||
              atualPagina.situacao === opPaginaCatalogoSituacao.CONCLUIDO
            ) {
              acumuladoPagina.push(index);
            }

            return acumuladoPagina;
          }, [])
        );

        const lastDonePage = lastIndex + 1;

        return paginaInicial || lastDonePage;
      });
    });
  }, [id, paginaInicial]);

  useEffect(() => {
    carregaPagina();
  }, [catalogo, id, currentPage, carregaPagina]);

  const handleTransformarImagem = useCallback(
    // eslint-disable-next-line consistent-return
    async (op, reprocessa = false) => {
      setLoading(true);
      try {
        const selecao = resizeRef.current.getSelection();

        const { idPagina, caminhoImagem: imagem } = paginaCatalogo;

        const parametros = selecao
          ? selecao.map((selection) => selection.borderBox)
          : [{ top: 0, left: 0, w: 0, h: 0 }];

        const orientacao = formRef.current.getFieldValue('orientacaoImagem');

        /** verificação de parâmetros para a operação */
        if ((op === 'crop' || 'crop_pdf') && parametros.length > 1) {
          return window.alert(
            'A operação "Cortar Imagem" não pode ser feita com mais de uma área selecionada.'
          );
        }

        const transformacao = {
          op,
          reprocessa,
          orientacao: String(orientacao),
          parametros,
          imagem,
        };

        /** envia para o servidor extrair os dados da página */
        const response = await api.post(
          `editor-editar/${id}/pagina/${idPagina}`,
          transformacao
        );

        const { caminhoImagem, urlImagem, orientacaoImagem } = response.data;

        // setPassosTransformacao((state) => [...state, transformacao]);

        setPaginaCatalogo({
          ...paginaCatalogo,
          orientacaoImagem,
          caminhoImagem,
          urlImagem,
        });

        if (resizeRef.current && resizeRef.current) {
          resizeRef.current.clearSelection();
        }
      } catch (err) {
        AppError(err, formRef);
      } finally {
        setLoading(false);
      }
    },
    [id, paginaCatalogo]
  );

  const throttledHandleTransformarImagem = useCallback(
    throttle(
      (op, reprocessa = false) => handleTransformarImagem(op, reprocessa),
      1000
    ),
    [handleTransformarImagem]
  );

  const handleUpdateImage = useCallback(async () => {
    const { idCatalogo } = catalogo;
    const { idPagina } = paginaCatalogo;

    try {
      await api.patch(`editor-editar/${idCatalogo}/pagina/${idPagina}`);

      toast.success('Página recarregada com sucesso!');

      carregaPagina();
    } catch (err) {
      AppError(err);
    }
  }, [catalogo, paginaCatalogo, carregaPagina]);

  const handleReprocessarImagem = useCallback(
    async (op, orientacao) => {
      /** atribui para o campo nova orientação da imagem */
      formRef.current.setFieldValue('orientacaoImagem', orientacao);
      /** chama rotina de transformação */
      throttledHandleTransformarImagem(op, true);
    },
    [throttledHandleTransformarImagem]
  );

  const handleConfirmar = useCallback(
    async (data) => {
      setLoading(true);
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { idPagina, situacao } = paginaCatalogo;

        const confirmacao =
          situacao === opPaginaCatalogoSituacao.CONCLUIDO
            ? window.confirm(
                'Ao confirmar as alterações será necessário refazer o mapeamento dessa página. Caso o catálogo esteja publicado, também será necessário republicá-lo, deseja continuar?'
              )
            : true;

        if (confirmacao) {
          const { descricao, nota, niveis, orientacaoImagem } = data;

          const nivelFormatado = Object.getOwnPropertyNames(niveis).reduce(
            (acumulado, atributo) => {
              if (atributo.includes('nivel_') && niveis[atributo] !== '') {
                acumulado.push({
                  // nivel: Number(atributo.replace(/\D/g, '')),
                  idNivel: niveis[atributo],
                });
              }

              return acumulado;
            },
            []
          );

          await api.put(`editor-editar/${id}/pagina/${idPagina}`, {
            descricao,
            nota,
            orientacao: String(orientacaoImagem),
            niveis: nivelFormatado,
          });

          carregaPagina();

          toast.success('Imagem formatada com sucesso!');
        }
      } catch (err) {
        AppError(err, formRef);
      } finally {
        setLoading(false);
      }
    },
    [id, paginaCatalogo, carregaPagina]
  );

  /** recarrega página do catálogo */
  const handleCancelar = useCallback(() => {
    carregaPagina();
  }, [carregaPagina]);

  /** redireciona para a referencia */
  const handleNavegar = useCallback(
    async (param = 'isClassificar' || 'isExtrair' || 'isAssociar') => {
      const { idPagina } = paginaCatalogo || {};

      if (!idPagina) {
        throw new AppError('Referência não encontrada!');
      }

      try {
        switch (param) {
          case 'isClassificar':
            history.replace(`/edicao/classificarAI`, {
              id,
              paginaInicial: idPagina,
            });
            break;
          case 'isExtrair':
            api.get(`editor-extrair/${id}`).then((response) => {
              const paginaIndex = response.data.paginaCatalogo.findIndex(
                (pagina) => pagina.referencia === idPagina
              );

              if (paginaIndex === -1)
                throw new AppError(
                  'Não há tabela com referência para esta página'
                );

              history.replace(`/edicao/extrairAI`, {
                id,
                paginaInicial: paginaIndex + 1,
              });
            });
            break;
          case 'isAssociar':
            api.get(`editor-associar/${id}`).then((response) => {
              const paginaIndex = response.data.paginaCatalogo.findIndex(
                (pagina) => pagina.idPagina === idPagina
              );

              if (paginaIndex === -1)
                throw new AppError(
                  'Esta página precisa ser editada antes de ir para o mapeamento'
                );

              history.replace(`/edicao/associarAI`, {
                id,
                paginaInicial: paginaIndex + 1,
              });
            });
            break;
          default:
            break;
        }
      } catch (err) {
        throw new AppError(err);
      }
    },
    [id, paginaCatalogo]
  );

  const handleVoltar = useCallback(() => {
    history.replace('/edicao/extrairAI', { id });
  }, [id]);

  return (
    <EditorContainer maxWidth={1440}>
      <TitleBar back onBack={() => handleVoltar()} title="Edição">
        <Action.BtnSquare
          type="button"
          title="Navegar para o processo de Classificação"
          className="btn-nav-ref"
          onClick={() => handleNavegar('isClassificar')}
        >
          <MdSchema size={30} />
        </Action.BtnSquare>
        <Action.BtnSquare
          type="button"
          title="Navegar para o processo de Extração"
          className="btn-nav-ref"
          onClick={() => handleNavegar('isExtrair')}
        >
          <MdTableRows size={30} />
        </Action.BtnSquare>
        <Action.BtnSquare
          type="button"
          title="Navegar para o processo de Mapeamento"
          className="btn-nav-ref"
          onClick={() => handleNavegar('isAssociar')}
        >
          <MdOutlineAddPhotoAlternate size={30} />
        </Action.BtnSquare>
        <button
          type="button"
          id="btn-next"
          onClick={() => history.replace('/edicao/associarAI', { id })}
        >
          Próximo
        </button>
      </TitleBar>

      <Loading visible={loading} />

      <EditorContainer.Wrapper>
        {catalogo && (
          <EditorContainer.EditorArea>
            <EditorContainer.EditorControl>
              <Pagination
                pageCount={totalPage}
                initialData={currentPage}
                onPageChange={(selected) => {
                  setCurrentPage(selected);
                }}
                location={{
                  catalogo: catalogo.idCatalogo,
                  pagina: paginaCatalogo ? paginaCatalogo.idPagina : 0,
                }}
              />

              {paginaCatalogo && (
                <Form
                  id="editorForm"
                  ref={formRef}
                  onSubmit={handleConfirmar}
                  autoComplete="off"
                >
                  <Form.Column>
                    <Scope path="niveis">
                      {nivelClassificacao.map((classificacao) => (
                        <AsyncCreatableSelectInput
                          key={classificacao.nivel}
                          id={classificacao.nivel}
                          name={`nivel_${classificacao.nivel}`}
                          label={classificacao.descricao}
                          defaultOptions
                          loadOptions={(value, cb) =>
                            getNivel(
                              {
                                id: classificacao.idCatalogo,
                                nivel: classificacao.nivel,
                                value,
                              },
                              cb
                            )
                          }
                          onCreate={(e, value) => {
                            setNivel({
                              e,
                              id: classificacao.idCatalogo,
                              value,
                            });
                          }}
                        />
                      ))}
                    </Scope>
                  </Form.Column>
                  <Form.Column>
                    <Input id="caminhoImagem" name="caminhoImagem" isHidden />
                    <Input
                      id="orientacaoImagem"
                      name="orientacaoImagem"
                      isHidden
                    />
                    <Input
                      id="descricao"
                      name="descricao"
                      label="Descrição"
                      type="text"
                    />
                    <Input
                      id="nota"
                      name="nota"
                      label="Descrição da nota"
                      type="text"
                    />
                  </Form.Column>
                </Form>
              )}
            </EditorContainer.EditorControl>

            <Container>
              <ToolBar>
                <button title="Salvar" type="submit" form="editorForm">
                  <MdDone size={16} />
                </button>
                <button type="button" title="Cancelar" onClick={handleCancelar}>
                  <MdClose size={16} />
                </button>
                <button
                  type="button"
                  title="Horizontal"
                  onClick={() => handleReprocessarImagem('orientation', 1)}
                >
                  <MdStayPrimaryLandscape size={16} />
                </button>
                <button
                  type="button"
                  title="Vertical"
                  onClick={() => handleReprocessarImagem('orientation', 0)}
                >
                  <MdStayPrimaryPortrait size={16} />
                </button>
                <button
                  type="button"
                  title="Cortar Imagem"
                  onClick={() => throttledHandleTransformarImagem('crop')}
                >
                  <MdCrop size={16} />
                </button>
                <button
                  type="button"
                  title="Limpar Imagem"
                  onClick={() => throttledHandleTransformarImagem('clear')}
                >
                  <MdDelete size={16} />
                </button>
                <button
                  type="button"
                  title="Limpar Fundo"
                  onClick={() => throttledHandleTransformarImagem('clear_bg')}
                >
                  <MdSelectAll size={16} />
                </button>
                <button
                  type="button"
                  title="Reverter alterações"
                  onClick={handleUpdateImage}
                >
                  <MdAutorenew size={16} />
                </button>
                <RxDividerVertical size={20} />
                <MdPictureAsPdf size={30} />
                <button
                  type="button"
                  title="Cortar Imagem(Processo PDF)"
                  onClick={() => throttledHandleTransformarImagem('crop_pdf')}
                >
                  <MdCrop size={16} />
                </button>
              </ToolBar>

              <Wrapper>
                {paginaCatalogo && (
                  // <TransformWrapper
                  //   defaultScale={1}
                  //   defaultPositionX={200}
                  //   defaultPositionY={100}
                  //   pan={{ disabled: !zoomCanMove }}
                  //   options={{ disabled: !zoom }}
                  // >
                  //   {({ zoomIn, zoomOut, resetTransform }) => (
                  //     <ZoomWrapper>
                  //       {zoom && (
                  //         <ZoomControl isVisible={zoom}>
                  //           <ZoomControl.BtnAct
                  //             ref={zoomInRef}
                  //             type="button"
                  //             onClick={zoomIn}
                  //           >
                  //             <MdZoomIn size={25} />
                  //           </ZoomControl.BtnAct>
                  //           <ZoomControl.BtnAct
                  //             ref={zoomOutRef}
                  //             type="button"
                  //             onClick={zoomOut}
                  //           >
                  //             <MdZoomOut size={25} />
                  //           </ZoomControl.BtnAct>
                  //           <ZoomControl.BtnAct
                  //             ref={zoomResetRef}
                  //             type="button"
                  //             onClick={resetTransform}
                  //           >
                  //             <MdYoutubeSearchedFor size={25} />
                  //           </ZoomControl.BtnAct>
                  //           <ZoomControl.BtnClose
                  //             type="button"
                  //             onClick={handleZoomControl_close}
                  //           >
                  //             <MdCancel size={15} />
                  //           </ZoomControl.BtnClose>
                  //         </ZoomControl>
                  //       )}
                  //       <TransformComponent>
                  <Resizeble id={Math.random()} ref={resizeRef}>
                    <Image
                      orientation={
                        String(paginaCatalogo.orientacaoImagem) ===
                        opPaginaCatalogoOrientacao.PORTRAIT
                          ? 'portrait'
                          : 'landscape'
                      }
                      src={paginaCatalogo.urlImagem}
                      alt={`Imagem ${paginaCatalogo.idPagina}`}
                    />
                  </Resizeble>
                  //       </TransformComponent>
                  //     </ZoomWrapper>
                  //   )}
                  // </TransformWrapper>
                )}
              </Wrapper>
            </Container>
          </EditorContainer.EditorArea>
        )}
      </EditorContainer.Wrapper>
    </EditorContainer>
  );
}

export default EditorEditarAI;
