import React, { useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppError } from '../../../../errors/AppError';
import api from '../../../../services/api';
import history from '../../../../services/history';

import { getModelo } from '../../../../lib/asyncUtils';

import TitleBar from '../../../../components/TitleBar';
import { FormWrapper, Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import AsyncSelect from '../../../../components/Form/Input/AsyncSelect';

function EmpresaModeloCriar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { idEmpresa } = location.state;

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const { idModelo, codiEspe, codiMode, ano } = data;

        await api.post(`/empresa/${idEmpresa}/modelo`, {
          idModelo,
          codiEspe,
          codiMode,
          ano,
        });

        toast.success('Relacionamento cadastrado com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err);
      }
    },
    [idEmpresa]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Novo Relacionamento" />
      <FormWrapper>
        <Form
          id="formEmprModelo"
          ref={formRef}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <AsyncSelect
                id="idModelo"
                name="idModelo"
                label="Modelo"
                cacheOptions
                defaultOptions
                loadOptions={getModelo}
                width={800}
              />
            </Form.Row>
            <Form.Row>
              <Input
                id="codiEspe"
                name="codiEspe"
                label="Código Espécie (Externo)"
                width={400}
                type="number"
              />
              <Input
                id="codiMode"
                name="codiMode"
                label="Código Modelo (Externo)"
                width={400}
                type="number"
              />
              <Input
                id="ano"
                name="ano"
                label="Ano"
                width={400}
                type="number"
              />
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formEmprModelo">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export default EmpresaModeloCriar;
