import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  MdClose,
  MdFormatIndentIncrease,
  MdPlaylistRemove,
  MdStayPrimaryLandscape,
  MdStayPrimaryPortrait,
  MdOutlineAddPhotoAlternate,
  MdImage,
  MdSchema,
  MdAutorenew,
  MdOutlineFilter1,
  MdOutlineFilter2,
  MdOutlineFilter3,
  MdOutlineFilter4,
  MdOutlineFilter5,
  MdOutlineRemoveRoad,
} from 'react-icons/md';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';
import { AppError } from '../../../errors/AppError';
import {
  opPaginaCatalogoSituacao,
  opPaginaCatalogoOrientacao,
  opEditorTabelaTipoExtracao,
  opSimNao,
} from '../../../lib/const';

import { EditorContainer } from '../../../components/Container';

import TitleBar from '../../../components/TitleBar';
import Pagination from '../../../components/EditorAI/Pagination';
import EditorTable from '../../../components/EditorAI/Table';
import Resizeble from '../../../components/Resizeble';
import Loading from '../../../components/Loading';

import EditorExtrairMapeamento from './mapeamento';

import { Container, Wrapper, ToolBar, Image } from './styles';
import { Action } from '../styles';

function EditorExtrairAI() {
  const location = useLocation();
  const { id, paginaInicial = 0 } = location.state;

  const dataRef = useRef();

  const [catalogo, setCatalogo] = useState(null);
  const [paginaCatalogo, setPaginaCatalogo] = useState(null);
  const [refItensPagina, setRefItensPagina] = useState([]);

  const [headers, setHeaders] = useState([]);
  const [mapping, setMapping] = useState(null);
  const [loading, setLoading] = useState(false);

  const [defaultSelectorGroup, setDefaultSelectorGroup] = useState([]);

  /** controle de páginas */
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPage, setTotalPage] = useState(0);

  const carregaPagina = useCallback(async () => {
    if (!catalogo) return;

    if (dataRef.current) {
      dataRef.current.clearSelection();
    }

    /** pega id da página  */
    const { idPagina } = catalogo.paginaCatalogo[currentPage - 1] || {};
    if (!idPagina) return;

    setLoading(true);
    try {
      /** carrega itens da página */
      const response = await api.get(`editor-extrair/${id}/pagina/${idPagina}`);

      setPaginaCatalogo({
        ...response.data,
        urlImagem: `${response.data.urlImagem}?v=${Date.now()}`,
      });

      setRefItensPagina(
        response.data.itemPaginaReferencia.map((item) => ({
          ...item,
          ...item.materialFabricante,
        }))
      );

      setMapping(null);
    } finally {
      setLoading(false);
    }
  }, [catalogo, id, currentPage]);

  useEffect(() => {
    /** informações do catálogo */
    api.get(`editor-extrair/${id}`).then((response) => {
      const paginas = response.data.paginaCatalogo;

      setCatalogo({
        ...response.data,
        paginas: response.data.paginaCatalogo.map((pagina) => pagina.idPagina),
      });

      setTotalPage(response.data.total);

      setCurrentPage(() => {
        /** index da última página concluída do catálogo */
        const lastIndex = Math.max(
          ...paginas.reduce((acumuladoPagina, atualPagina, index) => {
            // adiciona primeira página
            if (
              index === 0 ||
              atualPagina.situacao === opPaginaCatalogoSituacao.CONCLUIDO
            ) {
              acumuladoPagina.push(index);
            }

            return acumuladoPagina;
          }, [])
        );

        const lastDonePage = lastIndex + 1;

        return paginaInicial || lastDonePage;
      });
    });
  }, [id, paginaInicial]);

  useEffect(() => {
    carregaPagina();
  }, [location, catalogo, id, currentPage, carregaPagina]);

  const handleExtrairDados = useCallback(
    // eslint-disable-next-line consistent-return
    async (iaOrJs) => {
      const { idPagina, orientacaoImagem } = paginaCatalogo;

      const confirm = refItensPagina.length
        ? window.confirm(
            'O processo pode substituir os itens cadastrados anteriormente para essa página, deseja realmente continuar?'
          )
        : true;

      if (confirm) {
        setLoading(true);
        try {
          /** array das áreas de seleção */
          const selecao = dataRef.current.getSelection();

          /** atualiza as coordenadas padrão  */
          if (defaultSelectorGroup.length === 0) {
            const currentSelectorGroup = dataRef.current.getCurrentSelectorGroup();
            setDefaultSelectorGroup([...currentSelectorGroup]);
          }

          /** verifica se todas as seleções tem a mesma quantidade de colunas */
          const isColumnsOk = selecao
            .map((sel) => sel.points.length === selecao[0].points.length)
            .reduce((acc, crr) => acc && crr);

          if (!isColumnsOk) {
            return window.alert(
              'As tabelas não possuem a mesma quantidade de colunas, revise as colunas de todas as áreas selecionadas.'
            );
          }

          /** array de seleção para o servidor extrair os dados da página */
          const pages = selecao.map((sel) => ({
            pageNumber: idPagina,
            borderBox: sel.borderBox,
            points: sel.points.sort((a, b) => a - b),
          }));

          const response = await api.post(
            `editor-extrair/${id}/pagina/${idPagina}`,
            {
              orientacao: orientacaoImagem,
              pages,
              iaOrJs,
            }
          );

          const { formattedData, formattedHeader } = response.data;

          /** carrega pela primeira vez os cabeçalhos */
          if (headers.length !== formattedHeader.length) {
            setHeaders((prevHeader) =>
              formattedHeader.map((header, index, arrHeader) => {
                /** coluna ORDENA */
                if (index === arrHeader.length - 1)
                  return { ...header, option: 'ordena' };

                /** verifica se existe o header selecionado */
                const { option = null } =
                  prevHeader.find(
                    (h) =>
                      h.accessor === header.accessor && h.option !== 'ordena'
                  ) || {};

                return { ...header, option };
              })
            );
          }

          const { template } = catalogo;

          /** abre tela de mapeamento */
          setMapping({
            headers: formattedHeader,
            data: formattedData,
            template,
          });
        } catch (err) {
          AppError(err);
        } finally {
          setLoading(false);
        }
      }
    },
    [
      id,
      catalogo,
      headers,
      paginaCatalogo,
      refItensPagina,
      defaultSelectorGroup,
    ]
  );

  const handleMapearConfirmar = useCallback(
    async (mappingData) => {
      setLoading(true);

      try {
        setRefItensPagina(mappingData);

        const { idPagina } = paginaCatalogo;

        await api.put(`editor-extrair/${id}/pagina/${idPagina}`, {
          itensPagina: mappingData.map(({ valid, ...rest }) => ({ ...rest })),
        });

        carregaPagina();

        toast.success('Itens cadastrados com sucesso!');
      } catch (err) {
        AppError(err);
      } finally {
        setLoading(false);
      }
    },
    [id, paginaCatalogo, carregaPagina]
  );

  const handleMapearCancelar = useCallback((done) => {
    setMapping(null);

    if (dataRef.current && done) {
      dataRef.current.clearSelection();
    }
  }, []);

  const handleCancelar = useCallback(() => {
    /** recarrega página do catálogo */
    carregaPagina();
  }, [carregaPagina]);

  const handleClearHeaders = useCallback(() => {
    setHeaders([]);
  }, []);

  const handleClearSelectionGroup = useCallback(() => {
    setDefaultSelectorGroup([]);
  }, []);

  const handleReprocessarImagem = useCallback(
    async (orientacao) => {
      setLoading(true);
      try {
        const { idPagina, caminhoImagem: imagem } = paginaCatalogo;

        const transformacao = {
          op: 'orientation',
          reprocessa: true,
          orientacao: String(orientacao),
          imagem,
        };

        /** envia para o servidor extrair os dados da página */
        const response = await api.post(
          `editor-editar/${id}/pagina/${idPagina}`,
          transformacao
        );

        const { caminhoImagem, urlImagem, orientacaoImagem } = response.data;

        setPaginaCatalogo((state) => ({
          ...state,
          orientacaoImagem,
          caminhoImagem,
          urlImagem,
        }));

        if (dataRef.current) {
          dataRef.current.clearSelection();
        }
      } catch (err) {
        AppError(err);
      } finally {
        setLoading(false);
      }
    },
    [id, paginaCatalogo]
  );

  const handleUpdateImage = useCallback(async () => {
    const { idCatalogo } = catalogo;
    const { idPagina } = paginaCatalogo;

    try {
      await api.patch(`editor-extrair/${idCatalogo}/pagina/${idPagina}`);

      toast.success('Página recarregada com sucesso!');

      carregaPagina();
    } catch (err) {
      AppError(err);
    }
  }, [catalogo, paginaCatalogo, carregaPagina]);

  /** redireciona para a referencia */
  const handleNavegar = useCallback(
    (param = 'isClassificar' || 'isEditar' || 'isAssociar') => {
      const { idPagina, referencia } = paginaCatalogo || {};

      if ((param !== 'isClassificar' && !referencia) || !idPagina) {
        throw new AppError('Referência não encontrada!');
      }

      try {
        switch (param) {
          case 'isClassificar':
            history.replace(`/edicao/classificarAI`, {
              id,
              paginaInicial: idPagina,
            });
            break;
          case 'isEditar':
            api.get(`editor-editar/${id}`).then((response) => {
              const paginaIndex = response.data.paginaCatalogo.findIndex(
                (pagina) => pagina.idPagina === referencia
              );
              history.replace(`/edicao/editarAI`, {
                id,
                paginaInicial: paginaIndex + 1,
              });
            });
            break;
          case 'isAssociar':
            api.get(`editor-associar/${id}`).then((response) => {
              const paginaIndex = response.data.paginaCatalogo.findIndex(
                (pagina) => pagina.idPagina === referencia
              );

              if (paginaIndex === -1)
                throw new AppError(
                  'Esta página precisa ser editada antes de ir para o mapeamento'
                );

              history.replace(`/edicao/associarAI`, {
                id,
                paginaInicial: paginaIndex + 1,
              });
            });
            break;
          default:
            break;
        }
      } catch (err) {
        AppError(err);
      }
    },
    [id, paginaCatalogo]
  );

  const handleVoltar = useCallback(() => {
    history.replace('/edicao/classificarAI', { id });
  }, [id]);

  return (
    <EditorContainer maxWidth={1440}>
      <TitleBar back onBack={() => handleVoltar()} title="Extração">
        <Action.BtnSquare
          type="button"
          title="Navegar para o processo de Classificação"
          className="btn-nav-ref"
          onClick={() => handleNavegar('isClassificar')}
        >
          <MdSchema size={30} />
        </Action.BtnSquare>
        <Action.BtnSquare
          type="button"
          title="Navegar para o processo de Edição"
          className="btn-nav-ref"
          onClick={() => handleNavegar('isEditar')}
        >
          <MdImage size={30} />
        </Action.BtnSquare>
        <Action.BtnSquare
          type="button"
          title="Navegar para o processo de Mapeamento"
          className="btn-nav-ref"
          onClick={() => handleNavegar('isAssociar')}
        >
          <MdOutlineAddPhotoAlternate size={30} />
        </Action.BtnSquare>
        <button
          type="button"
          id="btn-next"
          onClick={() => history.replace('/edicao/editarAI', { id })}
        >
          Próximo
        </button>
      </TitleBar>

      <Loading visible={loading} />

      <EditorContainer.Wrapper>
        {catalogo && (
          <EditorContainer.EditorArea>
            <EditorContainer.EditorControl>
              <Pagination
                pageCount={totalPage}
                initialData={currentPage}
                onPageChange={(selected) => {
                  setCurrentPage(selected);
                }}
                location={{
                  catalogo: catalogo.idCatalogo,
                  pagina: paginaCatalogo ? paginaCatalogo.idPagina : 0,
                }}
              />

              {paginaCatalogo && refItensPagina && (
                <EditorTable
                  idCatalogo={id}
                  idPagina={paginaCatalogo.referencia}
                  itensPagina={refItensPagina}
                />
              )}
            </EditorContainer.EditorControl>

            <Container>
              <ToolBar>
                <button type="button" title="Cancelar" onClick={handleCancelar}>
                  <MdClose size={16} />
                </button>
                <button
                  type="button"
                  title="Horizontal"
                  onClick={() => handleReprocessarImagem(1)}
                >
                  <MdStayPrimaryLandscape size={16} />
                </button>
                <button
                  type="button"
                  title="Vertical"
                  onClick={() => handleReprocessarImagem(0)}
                >
                  <MdStayPrimaryPortrait size={16} />
                </button>
                <button
                  type="button"
                  title="Extrair Tabela - Convencional"
                  onClick={() =>
                    handleExtrairDados(
                      opEditorTabelaTipoExtracao.CONVENCIONAL_NODEJS
                    )
                  }
                >
                  <MdFormatIndentIncrease size={16} />
                </button>

                {process.env.REACT_APP_EXPERIMENTAL_IA_EXTRACAO ===
                  opSimNao.SIM && (
                  <>
                    <button
                      type="button"
                      title="OCR - Tradicional"
                      disabled
                      onClick={() =>
                        handleExtrairDados(
                          opEditorTabelaTipoExtracao.TRADICIONAL
                        )
                      }
                    >
                      <MdOutlineFilter1 size={16} />
                    </button>
                    <button
                      type="button"
                      title="OCR - Easy FAST"
                      disabled
                      onClick={() =>
                        handleExtrairDados(opEditorTabelaTipoExtracao.EASY_FAST)
                      }
                    >
                      <MdOutlineFilter2 size={16} />
                    </button>
                    <button
                      type="button"
                      title="OCR - Tabula"
                      onClick={() =>
                        handleExtrairDados(opEditorTabelaTipoExtracao.TABULA)
                      }
                    >
                      <MdOutlineFilter3 size={16} />
                    </button>
                    <button
                      type="button"
                      title="Camelot"
                      onClick={() =>
                        handleExtrairDados(opEditorTabelaTipoExtracao.CAMELOT)
                      }
                    >
                      <MdOutlineFilter4 size={16} />
                    </button>
                    <button
                      type="button"
                      title="Extrair tabela - PDF"
                      onClick={() =>
                        handleExtrairDados(
                          opEditorTabelaTipoExtracao.PDFPLUMBER
                        )
                      }
                    >
                      <MdOutlineFilter5 size={16} />
                    </button>
                  </>
                )}
                <button
                  type="button"
                  title="Limpar padrão de classificação de colunas"
                  onClick={handleClearHeaders}
                >
                  <MdPlaylistRemove size={16} />
                </button>
                <button
                  type="button"
                  title="Limpar padrão de grupo de seleção"
                  onClick={handleClearSelectionGroup}
                >
                  <MdOutlineRemoveRoad size={16} />
                </button>
                <button
                  type="button"
                  title="Reverter alterações"
                  onClick={handleUpdateImage}
                >
                  <MdAutorenew size={16} />
                </button>
              </ToolBar>
              <Wrapper>
                {paginaCatalogo && (
                  <Resizeble
                    id={Math.random()}
                    withLines
                    ref={dataRef}
                    defaultSelectorGroup={defaultSelectorGroup}
                  >
                    <Image
                      orientation={
                        String(paginaCatalogo.orientacaoImagem) ===
                        opPaginaCatalogoOrientacao.PORTRAIT
                          ? 'portrait'
                          : 'landscape'
                      }
                      src={paginaCatalogo.urlImagem}
                      alt={`Imagem ${paginaCatalogo.idPagina}`}
                    />
                  </Resizeble>
                )}
              </Wrapper>
            </Container>
          </EditorContainer.EditorArea>
        )}
      </EditorContainer.Wrapper>

      {mapping && (
        <EditorExtrairMapeamento
          headers={headers}
          data={mapping.data}
          template={mapping.template}
          onCancel={handleMapearCancelar}
          onMapping={handleMapearConfirmar}
        />
      )}
    </EditorContainer>
  );
}

export default EditorExtrairAI;
