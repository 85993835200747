import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import uniqBy from 'lodash.uniqby';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import {
  MdCancel,
  MdCheckBoxOutlineBlank,
  MdCenterFocusStrong,
  MdClose,
  MdCropFree,
  MdDelete,
  MdDone,
  MdExposureNeg1,
  MdExposurePlus1,
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
  MdOutlineFilter1,
  MdOutlineFilter2,
  MdOutlineFilter3,
  MdOutlineFilter4,
  MdOutlineLayersClear,
  MdLink,
  MdUnfoldLess,
  MdUnfoldMore,
  MdRadioButtonUnchecked,
  MdRotateLeft,
  MdRotateRight,
  MdStayPrimaryLandscape,
  MdStayPrimaryPortrait,
  MdYoutubeSearchedFor,
  MdZoomIn,
  MdZoomOut,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import {
  opEditorAssociarTipoExtracao,
  opPaginaCatalogoSituacao,
  opSimNao,
} from '../../lib/const';

import {
  getSvgCoordinates,
  createOcrGroup,
  createSvgLinkPdfProcess,
  centerCalculation,
  updateSvgPositionProcessPdf,
  centerSvgPdfProcess,
  scaleSvgProcessPdf,
} from '../../lib/mapeamentoUtils';

import api from '../../services/api';

import { Form } from '../Form';
import Input from '../Form/Input';

import {
  Container,
  Wrapper,
  EditorWrapper,
  ControlWrapper,
  Control,
  ToolBar,
  SVG,
} from './styles';

const EditorArea = forwardRef(
  (
    {
      catalogo,
      pagina,
      itensPagina,
      onSave,
      onCancel,
      onBeforeOCR,
      onAfterOCR,
      onAssociate,
      onLinkHighlight,
    },
    ref
  ) => {
    /** controles de posicionamento e seleção */
    const selectingRef = useRef(false);
    const selectedRef = useRef([]);
    const focusRef = useRef(null);
    const controlRef = useRef(null);

    const startCoordRef = useRef({ x: 0, y: 0 });
    const finishCoordRef = useRef({ x: 0, y: 0 });
    const minCoordRef = useRef({ x: 0, y: 0 });
    const maxCoordRef = useRef({ x: 0, y: 0 });

    const actionRef = useRef();
    const linkRef = useRef();

    const zoomRef = useRef();
    const zoomInRef = useRef(HTMLButtonElement);
    const zoomOutRef = useRef(HTMLButtonElement);
    const zoomResetRef = useRef(HTMLButtonElement);
    const zoomScaleRef = useRef(1);

    const sizeRef = useRef();
    const formSizeRef = useRef();

    const ocrMapperRef = useRef();
    const ocrMappingRef = useRef([]);
    const linkMappingRef = useRef([]);

    const svgRef = useRef();
    const rootRef = useRef();
    const selectorRef = useRef();

    const rootXRef = useRef(0);
    const rootYRef = useRef(0);
    const rootScaleRef = useRef(1);
    const rootRotateRef = useRef(0);

    const ACTION_STATE = {
      select: 'SELECT',
      deselect: 'DESELECT',
      move: 'MOVE',
      moveOne: 'MOVEONE',
      selectArea: 'SELECTAREA',
    };

    const INITIAL_STATE = {
      catalogId: 0,
      path: '',
      pageNum: 0,
      state: 0,
      type: 0,
      inDesign: false,
    };

    const LINK_FORM_TYPES = {
      circle: 0,
      rect: 1,
      ocr: 2,
    };

    const INITIAL_LINK_CIRCLE = {
      id: LINK_FORM_TYPES.circle,
      fontSize: 10,
      height: 10,
      width: 0,
    };

    const INITIAL_LINK_RECT = {
      id: LINK_FORM_TYPES.rect,
      fontSize: 10,
      height: 20,
      width: 20,
    };

    const LINK_SIZE_LIMIT = {
      min: 5,
      max: 36,
    };

    const [editor, setEditor] = useState(INITIAL_STATE);
    const [table, setTable] = useState([]);

    const [zoomControl, setZoomControl] = useState(false);
    const [zoomCanMove, setZoomCanMove] = useState(true);
    const [sizeControl, setSizeControl] = useState(false);

    const [centralizacaoX, setCentralizacaoX] = useState(0);
    const [centralizacaoY, setCentralizacaoY] = useState(0);
    const [pdfTool, setPdfTool] = useState(false);

    const [currLinkType, setCurrLinkType] = useState(0);
    const currLinkTypeRef = useRef(INITIAL_LINK_CIRCLE);

    const defLinkSize = useRef({
      height: 0,
      width: 0,
    });

    // const [elementToZoom, setElementToZoom] = useState();

    const oLinkSizeMode = useCallback((height = 0, width = 0) => {
      setTimeout(() => {
        const newSizeH = Number(height) || currLinkTypeRef.current.height;
        const newSizeW = Number(width) || currLinkTypeRef.current.width;

        if (formSizeRef.current) {
          formSizeRef.current.setFieldValue('sizeInputH', newSizeH);
          formSizeRef.current.setFieldValue('sizeInputW', newSizeW);
        }
      }, 200);
    }, []);

    /** valida e define o tamanho padrão de um link */
    const oChangeDefLinkSize = useCallback(
      (height = 0, width = 0, updateDefault = false) => {
        /** atualiza o tamanho para os links */
        if (updateDefault) {
          defLinkSize.current = {
            height,
            width,
          };
        }
        /** atualiza informações do campo */
        oLinkSizeMode(height, width);
      },
      [oLinkSizeMode]
    );

    useEffect(
      () => {
        let currSizeW = 0;
        let currSizeH = 0;

        if (sizeControl) {
          if (focusRef.current) {
            switch (Number(focusRef.current.node.childNodes[0].dataset.id)) {
              case LINK_FORM_TYPES.circle:
                currSizeW = focusRef.current.node.childNodes[0].getAttribute(
                  'r'
                );
                currSizeH = focusRef.current.node.childNodes[0].getAttribute(
                  'r'
                );
                break;
              case LINK_FORM_TYPES.rect:
                currSizeW = focusRef.current.node.childNodes[0].getAttribute(
                  'width'
                );
                currSizeH = focusRef.current.node.childNodes[0].getAttribute(
                  'height'
                );
                break;
              default:
                break;
            }
          }

          oChangeDefLinkSize(currSizeW, currSizeH);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [LINK_FORM_TYPES, sizeControl]
    );

    useEffect(() => {
      if (!pagina) return;

      const { idPagina, urlEdicao, situacao, tipo } = pagina;
      const { idCatalogo } = catalogo;

      setEditor({
        catalogId: idCatalogo,
        pageNum: idPagina,
        path: urlEdicao,
        state: situacao,
        type: tipo,
        inDesign: true,
      });

      setZoomControl(false);
      setSizeControl(false);

      // handleMapeamentoOCR();
    }, [catalogo, pagina]);

    useEffect(() => {
      if (!itensPagina) return;
      setTable(
        itensPagina.map((item) => ({
          ...item,
          quantidade: item.quantidade ? Number(item.quantidade) : 0,
        }))
      );
    }, [itensPagina]);

    const oCheckAssociation = useCallback(() => {
      onAssociate(linkMappingRef.current.map((item) => item.textContent));
    }, [onAssociate]);

    useImperativeHandle(ref, () => ({
      highlightLinks: (links) => {
        linkMappingRef.current = linkMappingRef.current.map((node) => {
          const classes = node.getAttribute('class');

          if (links.includes(node.textContent)) {
            /** não aplica highlight se ja for um ou se estiver em conflito */
            if (!classes.includes('highlight') && !classes.includes('warning'))
              node.setAttribute('class', `${classes} highlight`.trim());
          } else if (classes && classes.includes('highlight'))
            node.setAttribute('class', classes.split('highlight')[0].trim());

          return node;
        });
      },
      getLinked: () => oCheckAssociation(),
    }));

    const handleOCRLimpar = useCallback(() => {
      /** limpa coleção e coordenadas */
      ocrMappingRef.current = [];

      selectorRef.current.setAttribute('x', 0);
      selectorRef.current.setAttribute('y', 0);
      selectorRef.current.setAttribute('width', 0);
      selectorRef.current.setAttribute('height', 0);

      /** remove grupo do SVG */
      const ocrMapper = Array.from(rootRef.current.childNodes).find(
        (attr) => attr.id === 'ocrGroup'
      );
      if (ocrMapper) {
        ocrMapper.remove();
      }
      ocrMapperRef.current = null;
    }, []);

    const handleOCRLocalizar = useCallback(
      async (tipoExtracao = opEditorAssociarTipoExtracao.TRADICIONAL) => {
        /** executa evento antes da consulta */
        if (onBeforeOCR) {
          onBeforeOCR();
        }

        try {
          const x = selectorRef.current.x.baseVal.value;
          const y = selectorRef.current.y.baseVal.value;
          const width = selectorRef.current.width.baseVal.value;
          const height = selectorRef.current.height.baseVal.value;

          /** limpa mapeamento anterior */
          handleOCRLimpar();

          /** cria grupo para mapeamento do serviço de OCR */
          const ocrMapper = document.createElementNS(
            rootRef.current.namespaceURI,
            'svg:g',
            rootRef.current
          );
          ocrMapper.setAttribute('id', 'ocrGroup');
          rootRef.current.appendChild(ocrMapper);

          ocrMapperRef.current = ocrMapper;

          const response = await api.post(
            `/editor-associar/${catalogo.idCatalogo}/pagina/${pagina.idPagina}`,
            { tipoExtracao, parametros: { x, y, width, height } }
          );

          /** cria links OCR */
          response.data.forEach((pt, index) => {
            /** cria elemento */
            const link = document.createElementNS(
              svgRef.current.namespaceURI,
              'a',
              svgRef.current
            );
            link.id = index;
            link.setAttribute('x', pt.x);
            link.setAttribute('y', pt.y - 5);
            link.setAttribute('class', '');
            // código preventivo para o link não ser executado no design
            link.addEventListener('click', (e) => e.preventDefault());
            ocrMapperRef.current.appendChild(link);

            /** cria retângulo ocr */
            const linkForm = document.createElementNS(
              svgRef.current.namespaceURI,
              'svg:rect',
              svgRef.current
            );

            /** cria texto */
            const linkText = document.createElementNS(
              svgRef.current.namespaceURI,
              'svg:text',
              svgRef.current
            );

            const linkFormWidth = pt.text.length * 6;

            linkForm.setAttribute('x', pt.x + 10);
            linkForm.setAttribute('y', pt.y - 5);
            linkForm.setAttribute('height', '15px');
            linkForm.setAttribute('width', `${linkFormWidth}px`);
            linkForm.setAttribute(
              'class',
              pt.conf > 70 ? 'success' : 'warning'
            );
            linkForm.setAttribute('data-id', LINK_FORM_TYPES.ocr);
            link.appendChild(linkForm);

            linkText.setAttribute('x', pt.x + 10);
            linkText.setAttribute('y', pt.y + 10 - 5);
            linkText.setAttribute('font-size', '10px');
            linkText.setAttribute(
              'class',
              pt.conf > 70 ? 'success' : 'warning'
            );
            linkText.textContent = pt.text;
            link.appendChild(linkText);

            ocrMappingRef.current.push({ attr: link, ocr: pt });
          });
        } catch (err) {
          toast.error(err);
        } finally {
          /** executa evento depois da consulta */
          if (onAfterOCR) {
            onAfterOCR();
          }
        }
      },
      [
        LINK_FORM_TYPES,
        svgRef,
        selectorRef,
        catalogo,
        pagina,
        handleOCRLimpar,
        onBeforeOCR,
        onAfterOCR,
      ]
    );

    const handlePDFLocalizar = useCallback(
      async (tipoExtracao = opEditorAssociarTipoExtracao.TRADICIONAL) => {
        /** executa evento antes da consulta */
        if (onBeforeOCR) {
          onBeforeOCR();
        }
        setPdfTool(true);
        try {
          const { x, y, width, height } = getSvgCoordinates(selectorRef);
          /** limpa mapeamento anterior */
          handleOCRLimpar();
          const ocrMapper = createOcrGroup(rootRef);
          ocrMapperRef.current = ocrMapper;

          const response = await api.post(
            `/editor-associar/${catalogo.idCatalogo}/pagina/${pagina.idPagina}`,
            { tipoExtracao, parametros: { x, y, width, height } }
          );

          /** cria links PDF */
          response.data.forEach((pt, index) => {
            const link = createSvgLinkPdfProcess(
              svgRef.current.namespaceURI,
              ocrMapperRef,
              pt,
              index
            );
            ocrMappingRef.current.push({ attr: link, ocr: pt });
          });

          const { centroX, centroY } = centerCalculation(
            pagina.orientacaoImagem,
            x,
            y,
            width,
            height
          );

          centerSvgPdfProcess(svgRef, rootRef, centroX, centroY);
          setCentralizacaoX(centroX);
          setCentralizacaoY(centroY);
        } catch (err) {
          toast.error(err);
        } finally {
          /** executa evento depois da consulta */
          if (onAfterOCR) {
            onAfterOCR();
          }
        }
      },
      [
        svgRef,
        selectorRef,
        catalogo,
        pagina,
        handleOCRLimpar,
        onBeforeOCR,
        onAfterOCR,
      ]
    );

    const handlePositionPdfProcess = useCallback((coordX, coordY) => {
      updateSvgPositionProcessPdf(svgRef, rootRef, coordX, coordY);
    }, []);

    const handleCenterPdfProcess = useCallback(() => {
      centerSvgPdfProcess(svgRef, rootRef, centralizacaoX, centralizacaoY);
    }, [centralizacaoX, centralizacaoY]);

    const handleScalePdfProcess = useCallback(
      (valor) => {
        scaleSvgProcessPdf(svgRef, rootRef, rootScaleRef, valor);
        centerSvgPdfProcess(svgRef, rootRef, centralizacaoX, centralizacaoY);
      },
      [centralizacaoX, centralizacaoY]
    );

    const handleHoverHighlight = useCallback(
      (id, state) => {
        linkMappingRef.current.forEach((node) => {
          const classes = node.getAttribute('class');

          if (node.textContent === id)
            node.setAttribute(
              'class',
              (state
                ? `${classes} highlight`
                : classes.split('highlight')[0]
              ).trim()
            );
        });

        const highlightedItems = linkMappingRef.current
          .filter((node) => node.getAttribute('class').includes('highlight'))
          .map((node) => node.textContent);

        onLinkHighlight(highlightedItems, 'editor');
      },
      [onLinkHighlight]
    );

    const mappingElement = useCallback(
      (lstNode) => {
        Array.from(lstNode).map((node) => {
          if (
            node.nodeName === 'svg:path' ||
            node.nodeName === 'svg:rect' ||
            node.nodeName === 'svg:image' ||
            node.nodeName === 'a'
          ) {
            /** tratamento para links já carregados */
            if (node.nodeName === 'a') {
              /** coloca o nome do grupo do link */
              if (node.parentElement.nodeName === 'svg:g') {
                node.parentElement.id = 'linkGroup';
              }
              node.setAttribute('class', '');
              node.addEventListener('click', (e) => e.preventDefault());
              node.addEventListener('mouseenter', () => {
                handleHoverHighlight(node.textContent, true);
              });
              node.addEventListener('mouseleave', () => {
                handleHoverHighlight(node.textContent, false);
              });

              linkMappingRef.current.push(node);
            }

            node.id = Math.random();
          }

          if (node.hasChildNodes()) {
            return mappingElement(node.childNodes);
          }

          return node;
        });
      },
      [handleHoverHighlight]
    );

    const createLink = useCallback(
      (type, x, y, radius, height, width, id, text, textSize, href) => {
        /** verifica se o grupo dos links já foi criado */
        linkRef.current = document.getElementById('linkGroup');
        if (!linkRef.current) {
          linkRef.current = document.createElementNS(
            svgRef.current.namespaceURI,
            'svg:g',
            svgRef.current
          );
          linkRef.current.id = 'linkGroup';

          /** adiciona elementos no nó raiz */
          rootRef.current.appendChild(linkRef.current);
        }

        /** criando o elemento link do item */
        const link = document.createElementNS(
          svgRef.current.namespaceURI,
          'a',
          svgRef.current
        );

        link.setAttribute('xlink:href', href);
        link.setAttribute('target', '_blank');
        link.setAttribute('class', '');
        link.id = id;
        /** código preventivo para o link não ser executado no design */
        link.addEventListener('click', (e) => e.preventDefault());

        link.addEventListener('mouseenter', () => {
          if (link.matches(':hover'))
            handleHoverHighlight(link.textContent, true);
        });
        link.addEventListener('mouseleave', () => {
          if (!link.matches(':hover'))
            handleHoverHighlight(link.textContent, false);
        });

        linkRef.current.appendChild(link);

        let linkForm;
        let linkText;

        /** define o formato do link */
        switch (type) {
          /** cria círculo */
          case LINK_FORM_TYPES.circle:
            linkForm = document.createElementNS(
              svgRef.current.namespaceURI,
              'svg:circle',
              svgRef.current
            );

            linkForm.setAttribute('data-id', String(LINK_FORM_TYPES.circle));
            linkForm.setAttribute('class', 'inject');
            linkForm.setAttribute('stroke', '#ccc');
            linkForm.setAttribute('fill', '#fff');
            linkForm.setAttribute('cx', x);
            linkForm.setAttribute('cy', y);
            linkForm.setAttribute('r', radius);
            link.appendChild(linkForm);

            linkText = document.createElementNS(
              svgRef.current.namespaceURI,
              'svg:text',
              svgRef.current
            );
            linkText.setAttribute('x', x);
            linkText.setAttribute('y', y);
            linkText.setAttribute('text-anchor', 'middle');
            linkText.setAttribute('alignment-baseline', 'middle');
            // linkText.setAttribute('font-size', `${textSize}px`);
            linkText.setAttribute('font-size', `${radius}px`);
            linkText.textContent = text;
            link.appendChild(linkText);

            break;
          /** cria retângulo */
          case LINK_FORM_TYPES.rect:
            linkForm = document.createElementNS(
              svgRef.current.namespaceURI,
              'svg:rect',
              svgRef.current
            );

            linkForm.setAttribute('data-id', String(LINK_FORM_TYPES.rect));
            linkForm.setAttribute('class', 'inject');
            linkForm.setAttribute('stroke', '#ccc');
            linkForm.setAttribute('fill', '#fff');
            linkForm.setAttribute('x', Number(x - textSize / 2));
            linkForm.setAttribute('y', Number(y - 10));
            linkForm.setAttribute('height', height);
            linkForm.setAttribute('width', width);
            linkForm.setAttribute('rx', '2');
            linkForm.setAttribute('ry', '2');
            link.appendChild(linkForm);

            linkText = document.createElementNS(
              svgRef.current.namespaceURI,
              'svg:text',
              svgRef.current
            );
            linkText.setAttribute(
              'x',
              linkForm.x.baseVal.value + linkForm.width.baseVal.value / 2
            );
            linkText.setAttribute(
              'y',
              linkForm.y.baseVal.value + linkForm.height.baseVal.value / 2
            );
            linkText.setAttribute('text-anchor', 'middle');
            linkText.setAttribute('alignment-baseline', 'middle');
            // linkText.setAttribute('font-size', `${textSize}px`);
            linkText.setAttribute('font-size', `${Math.trunc(height / 2)}px`);
            linkText.textContent = text;
            link.appendChild(linkText);
            break;
          default:
            break;
        }

        linkMappingRef.current.push(link);
      },
      [LINK_FORM_TYPES, handleHoverHighlight]
    );

    const oCheckConflict = useCallback(() => {
      /** desconta padding como margem de segurança */
      const padding = 3;

      if (!(linkMappingRef && linkMappingRef.current)) return;

      const mappingPos = linkMappingRef.current.map((item) => {
        const shape = item.childNodes[0];

        /** reprocessa os itens com classe padrão */
        shape.setAttribute('class', 'inject');

        if (shape.nodeName === 'svg:circle') {
          const cx = Number(shape.getAttribute('cx'));
          const cy = Number(shape.getAttribute('cy'));
          const r = Number(shape.getAttribute('r'));

          const top = cy - r;
          const left = cx - r;
          const width = r * 2;
          const height = r * 2;

          return { shape, top, left, width, height };
        }

        const left = Number(shape.getAttribute('x'));
        const top = Number(shape.getAttribute('y'));
        const width = Number(shape.getAttribute('width'));
        const height = Number(shape.getAttribute('height'));

        return { shape, top, left, width, height };
      });

      mappingPos.forEach((item) => {
        const conflicts = mappingPos.filter(
          (itemFilter) =>
            (item.left - padding >= itemFilter.left ||
              item.left - padding + item.width >= itemFilter.left) &&
            item.left + item.width / 2 - padding <=
              itemFilter.left + itemFilter.width &&
            (item.top - padding >= itemFilter.top ||
              item.top + item.height - padding >= itemFilter.top) &&
            item.top + item.height / 2 - padding <=
              itemFilter.top + itemFilter.height
        );

        if (conflicts.length > 1) {
          conflicts.forEach((itemConflict) =>
            itemConflict.shape.setAttribute('class', 'warning')
          );
        }
      });
    }, []);

    const oLink = useCallback(
      (data) => {
        const { catalogId, pageNum: pageId } = editor;
        const { id, fontSize, width, height } = currLinkTypeRef.current;

        const currHeight = defLinkSize.current.height || height;
        const currWidth = defLinkSize.current.width || width;

        /** transforma IDs com 0 à esquerda em IDs sem 0 à esquerda ("01" significa o mesmo que "1") */
        const formattedIdItem = Number.isNaN(data.idItem)
          ? data.idItem
          : Number(data.idItem);

        /** busca o item no mapeamento OCR */
        const dotted = ocrMappingRef.current.filter((item) => {
          const formattedOcr = Number.isNaN(item.ocr.text)
            ? item.ocr.text
            : Number(item.ocr.text);

          return String(formattedOcr) === String(formattedIdItem);
        });

        /** caso não encontre pelo método OCR, interrompe o processo */
        if (!dotted.length) return;

        dotted.forEach(({ attr }) => {
          createLink(
            id,
            Number(attr.childNodes[0].getAttribute('x')) - 10,
            Number(attr.childNodes[0].getAttribute('y')) + 10,
            currHeight,
            currHeight,
            currWidth,
            data.partnumber,
            data.idItem,
            fontSize,
            `/${catalogId}/${pageId}/${data.idItem}`
          );
        });

        /** verifica associações */
        oCheckAssociation();

        /** verifica conflito */
        oCheckConflict();
      },
      [createLink, editor, oCheckAssociation, oCheckConflict]
    );

    /** Retorna a posição do mouse no objeto SVGPoint { x: number, y: number } */
    const oMousePosSVG = useCallback(
      (e) => {
        let p = svgRef.current.createSVGPoint();
        p.x = e.clientX;
        p.y = e.clientY;
        const ctm = rootRef.current.getScreenCTM().inverse();
        p = p.matrixTransform(ctm);
        return p;
      },
      [rootRef]
    );

    /** Seleciona um link e define foco nele */
    const oSelectElement = useCallback(
      (el) => {
        const isSelected = !!selectedRef.current.find(
          (element) => element.node === el.node
        );

        if (!isSelected) {
          selectedRef.current.push(el);
        }

        /** limpa o foco atual */
        if (focusRef && focusRef.current) {
          const classes = focusRef.current.node
            .getAttribute('class')
            .split('focused');

          focusRef.current.node.setAttribute(
            'class',
            classes[0].trim() + (classes[1] || '')
          );
        }

        /** difene novo foco */
        focusRef.current = el;
        focusRef.current.node.setAttribute(
          'class',
          `selected ${
            selectedRef.current && selectedRef.current.length > 1
              ? 'focused'
              : ''
          }`.trim()
        );

        let currSizeW = 0;
        let currSizeH = 0;

        switch (Number(focusRef.current.node.childNodes[0].dataset.id)) {
          case LINK_FORM_TYPES.circle:
            currSizeH = focusRef.current.node.childNodes[0].getAttribute('r');
            break;
          case LINK_FORM_TYPES.rect:
            currSizeW = focusRef.current.node.childNodes[0].getAttribute(
              'width'
            );
            currSizeH = focusRef.current.node.childNodes[0].getAttribute(
              'height'
            );
            break;
          default:
            break;
        }

        oLinkSizeMode(currSizeH, currSizeW);
      },
      [LINK_FORM_TYPES, selectedRef, focusRef, oLinkSizeMode]
    );

    /** desseleciona um link */
    const oDeselectElement = useCallback(
      (el) => {
        const classes = el.node.getAttribute('class').split('selected');

        el.node.setAttribute('class', classes[0].trim() + (classes[1] || ''));

        selectedRef.current = selectedRef.current.filter(
          (element) => element.node !== el.node
        );
      },
      [selectedRef]
    );

    /** limpa seleção de links */
    const oClearSelection = useCallback(() => {
      /** limpa foco */
      if (focusRef && focusRef.current) {
        focusRef.current = null;
      }

      /** limpa seleção */
      if (selectedRef && selectedRef.current) {
        selectedRef.current.forEach((el) =>
          el.node.setAttribute(
            'class',
            el.node.getAttribute('class').split('selected')[0].trim()
          )
        );
        selectedRef.current = [];
      }

      oLinkSizeMode(0, 0);
    }, [selectedRef, oLinkSizeMode]);

    const oClearSelector = useCallback(() => {
      /** limpa área de seleção */
      if (selectorRef && selectorRef.current) {
        selectorRef.current.setAttribute('x', 0);
        selectorRef.current.setAttribute('y', 0);
        selectorRef.current.setAttribute('width', 0);
        selectorRef.current.setAttribute('height', 0);

        startCoordRef.current = { x: 0, y: 0 };
        finishCoordRef.current = { x: 0, y: 0 };
        maxCoordRef.current = { x: 0, y: 0 };
        minCoordRef.current = { x: 0, y: 0 };
      }
    }, [selectorRef]);

    const oRemove = useCallback((el) => {
      const drop = el.parentElement || null;

      if (!drop) return el;

      if (drop.childNodes.length > 1) return el;

      return oRemove(drop);
    }, []);

    const handleDragOverSVG = useCallback((e) => {
      e.preventDefault();
    }, []);

    const handleDropSVG = useCallback(
      (e) => {
        e.preventDefault();

        const m = oMousePosSVG(e);

        const data = JSON.parse(e.dataTransfer.getData('text/row-list'));
        if (!data) return;

        const { catalogId, pageNum: pageId } = editor;

        const dotted = ocrMappingRef.current.filter(
          (item) => item.ocr.text === String(data.idItem)
        );

        const { id, fontSize, width, height } = currLinkTypeRef.current;

        /** considera o tamanho personalizado ou o padrão do link */
        const currHeight = defLinkSize.current.height || height;
        const currWidth = defLinkSize.current.width || width;

        if (!dotted.length) {
          createLink(
            id,
            m.x,
            m.y,
            currHeight,
            currHeight,
            currWidth,
            data.partnumber,
            data.idItem,
            fontSize,
            `/${catalogId}/${pageId}/${data.idItem}`
          );
        }

        dotted.forEach(({ attr }) => {
          createLink(
            id,
            Number(attr.childNodes[0].getAttribute('x')) - 10,
            Number(attr.childNodes[0].getAttribute('y')) + 10,
            currHeight,
            currHeight,
            currWidth,
            data.partnumber,
            data.idItem,
            fontSize,
            `/${catalogId}/${pageId}/${data.idItem}`
          );

          /** remove elemento do mapeamento principal */
          const mappingIndex = ocrMappingRef.current.findIndex(
            (findAttr) => findAttr.attr.id === attr.id
          );
          ocrMappingRef.current.splice(mappingIndex, 1);

          /** remove elemento da tela */
          oRemove(attr).remove();
        });

        /** associa link com o item na tabela */
        oCheckAssociation();

        /** verifica conflito */
        oCheckConflict();
      },
      [
        editor,
        oMousePosSVG,
        oRemove,
        createLink,
        oCheckAssociation,
        oCheckConflict,
      ]
    );

    const handleDragOver = useCallback((e) => {
      const isLink = e.dataTransfer.types.includes('image/svg+xml');
      if (isLink) e.preventDefault();
    }, []);

    const handleMouseMove = useCallback(
      (e) => {
        if (!selectingRef.current) return;

        const m = oMousePosSVG(e);

        const isLink = !!(
          e.target.nodeName === 'svg:circle' || e.target.nodeName === 'svg:rect'
        );

        const { shiftKey, ctrlKey, altKey } = e;

        /** Shift+Click */
        if (shiftKey && !ctrlKey && !altKey) {
          if (isLink && e.target.parentElement === focusRef.current.node) {
            actionRef.current = ACTION_STATE.move;
          }
        }

        /** Shift+Ctrl+Click Disponível */
        /** Shift+Alt+Click Disponível */
        /** Shift+Ctrl+Alt+Click Disponível */

        /** Ctrl+Click  */
        if (!shiftKey && ctrlKey && !altKey) {
          if (!selectedRef.current.length) {
            actionRef.current = ACTION_STATE.selectArea;
          }
        }

        /** Ctrl+Alt+Click Disponível */

        /** Alt+Click Disponível */
        if (!shiftKey && !ctrlKey && altKey) {
          if (isLink && e.target.parentElement === controlRef.current) {
            actionRef.current = ACTION_STATE.moveOne;
          }
        }

        /** Click */
        if (!shiftKey && !ctrlKey && !altKey) {
          if (isLink) {
            actionRef.current = ACTION_STATE.move;
          }
        }

        switch (actionRef.current) {
          case ACTION_STATE.move: {
            /** verifica se há links selecionados */
            if (selectedRef.current.length === 0) return;

            /** vetores de deslocamento para x e y */
            const xVector =
              m.x -
              Number(
                focusRef.current.node.childNodes[0].getAttribute(
                  `${
                    Number(focusRef.current.node.childNodes[0].dataset.id) ===
                    LINK_FORM_TYPES.circle
                      ? 'cx'
                      : 'x'
                  }`
                )
              );
            const yVector =
              m.y -
              Number(
                focusRef.current.node.childNodes[0].getAttribute(
                  `${
                    Number(focusRef.current.node.childNodes[0].dataset.id) ===
                    LINK_FORM_TYPES.circle
                      ? 'cy'
                      : 'y'
                  }`
                )
              );

            /** offsets do link em foco em x e y */
            const xOffset =
              Number(focusRef.current.node.childNodes[0].dataset.id) ===
              LINK_FORM_TYPES.circle
                ? null
                : Number(
                    focusRef.current.node.childNodes[0].width.baseVal.value
                  ) / 2;
            const yOffset =
              Number(focusRef.current.node.childNodes[0].dataset.id) ===
              LINK_FORM_TYPES.circle
                ? null
                : Number(
                    focusRef.current.node.childNodes[0].height.baseVal.value
                  ) / 2;

            selectedRef.current.forEach((element) => {
              const linkForm = element.node.childNodes[0];
              const linkText = element.node.childNodes[1];

              const { id: tipo = null } = linkForm.dataset || {};

              switch (Number(tipo)) {
                case LINK_FORM_TYPES.circle: {
                  /** posição da forma */
                  const xF = Number(linkForm.getAttribute('cx'));
                  const yF = Number(linkForm.getAttribute('cy'));
                  /** posição do texto */
                  const xT = Number(linkText.getAttribute('x'));
                  const yT = Number(linkText.getAttribute('y'));

                  /** nova posição da forma */
                  linkForm.setAttribute('cx', xF + xVector - xOffset);
                  linkForm.setAttribute('cy', yF + yVector - yOffset);
                  /** nova posição do texto */
                  linkText.setAttribute('x', xT + xVector - xOffset);
                  linkText.setAttribute('y', yT + yVector - yOffset);
                  break;
                }
                case LINK_FORM_TYPES.rect:
                case LINK_FORM_TYPES.ocr: {
                  /** posição da forma */
                  const xF = Number(linkForm.getAttribute('x'));
                  const yF = Number(linkForm.getAttribute('y'));
                  /** posição do texto */
                  const xT = Number(linkText.getAttribute('x'));
                  const yT = Number(linkText.getAttribute('y'));

                  /** nova posição da forma */
                  linkForm.setAttribute('x', xF + xVector - xOffset);
                  linkForm.setAttribute('y', yF + yVector - yOffset);
                  /** nova posição do texto */
                  linkText.setAttribute('x', xT + xVector - xOffset);
                  linkText.setAttribute('y', yT + yVector - yOffset);
                  break;
                }
                default:
                  break;
              }
            });
            break;
          }

          case ACTION_STATE.moveOne:
            {
              const element = selectedRef.current.find(
                (el) => el.node === controlRef.current
              );

              if (!element) return;
              /** vetores de deslocamento para x e y */
              const xVector =
                m.x -
                Number(
                  element.node.childNodes[0].getAttribute(
                    `${
                      Number(element.node.childNodes[0].dataset.id) ===
                      LINK_FORM_TYPES.circle
                        ? 'cx'
                        : 'x'
                    }`
                  )
                );
              const yVector =
                m.y -
                Number(
                  element.node.childNodes[0].getAttribute(
                    `${
                      Number(element.node.childNodes[0].dataset.id) ===
                      LINK_FORM_TYPES.circle
                        ? 'cy'
                        : 'y'
                    }`
                  )
                );

              /** offsets do link em foco em x e y */
              const xOffset =
                Number(element.node.childNodes[0].dataset.id) ===
                LINK_FORM_TYPES.circle
                  ? null
                  : Number(element.node.childNodes[0].width.baseVal.value) / 2;
              const yOffset =
                Number(element.node.childNodes[0].dataset.id) ===
                LINK_FORM_TYPES.circle
                  ? null
                  : Number(element.node.childNodes[0].height.baseVal.value) / 2;

              const linkForm = element.node.childNodes[0];
              const linkText = element.node.childNodes[1];

              const { id: tipo = null } = linkForm.dataset || {};

              switch (Number(tipo)) {
                case LINK_FORM_TYPES.circle: {
                  /** posição da forma */
                  const xF = Number(linkForm.getAttribute('cx'));
                  const yF = Number(linkForm.getAttribute('cy'));
                  /** posição do texto */
                  const xT = Number(linkText.getAttribute('x'));
                  const yT = Number(linkText.getAttribute('y'));

                  /** nova posição da forma */
                  linkForm.setAttribute('cx', xF + xVector - xOffset);
                  linkForm.setAttribute('cy', yF + yVector - yOffset);
                  /** nova posição do texto */
                  linkText.setAttribute('x', xT + xVector - xOffset);
                  linkText.setAttribute('y', yT + yVector - yOffset);
                  break;
                }
                case LINK_FORM_TYPES.rect:
                case LINK_FORM_TYPES.ocr: {
                  /** posição da forma */
                  const xF = Number(linkForm.getAttribute('x'));
                  const yF = Number(linkForm.getAttribute('y'));
                  /** posição do texto */
                  const xT = Number(linkText.getAttribute('x'));
                  const yT = Number(linkText.getAttribute('y'));

                  /** nova posição da forma */
                  linkForm.setAttribute('x', xF + xVector - xOffset);
                  linkForm.setAttribute('y', yF + yVector - yOffset);
                  /** nova posição do texto */
                  linkText.setAttribute('x', xT + xVector - xOffset);
                  linkText.setAttribute('y', yT + yVector - yOffset);
                  break;
                }
                default:
                  break;
              }
            }
            break;
          case ACTION_STATE.selectArea: {
            /** define valores finais para a área de seleção */
            if (
              startCoordRef.current.x === 0 &&
              startCoordRef.current.y === 0
            ) {
              startCoordRef.current.x = m.x;
              startCoordRef.current.y = m.y;
            }

            finishCoordRef.current.x = m.x;
            finishCoordRef.current.y = m.y;

            minCoordRef.current.x = Math.min(
              startCoordRef.current.x,
              finishCoordRef.current.x
            );
            maxCoordRef.current.x = Math.max(
              startCoordRef.current.x,
              finishCoordRef.current.x
            );
            minCoordRef.current.y = Math.min(
              startCoordRef.current.y,
              finishCoordRef.current.y
            );
            maxCoordRef.current.y = Math.max(
              startCoordRef.current.y,
              finishCoordRef.current.y
            );

            selectorRef.current.setAttribute('x', minCoordRef.current.x);
            selectorRef.current.setAttribute('y', minCoordRef.current.y);
            selectorRef.current.setAttribute(
              'width',
              maxCoordRef.current.x - minCoordRef.current.x
            );
            selectorRef.current.setAttribute(
              'height',
              maxCoordRef.current.y - minCoordRef.current.y
            );

            /** seleciona itens dentro da área */
            const linkMappingPos = linkMappingRef.current.map((item) => {
              const shape = item.childNodes[0];

              const x = Number(
                shape.getAttribute(shape.nodeName === 'svg:circle' ? 'cx' : 'x')
              );
              const y = Number(
                shape.getAttribute(shape.nodeName === 'svg:circle' ? 'cy' : 'y')
              );

              return { attr: item, x, y };
            });

            const mappingOCRPos = ocrMappingRef.current.map((item) => {
              const x = Number(item.attr.childNodes[0].getAttribute('x'));
              const y = Number(item.attr.childNodes[0].getAttribute('y'));

              return { attr: item.attr, x, y };
            });

            const itemsPos = [...linkMappingPos, ...mappingOCRPos];

            itemsPos.forEach((item) => {
              if (
                item.x >= minCoordRef.current.x &&
                item.x <= maxCoordRef.current.x &&
                item.y >= minCoordRef.current.y &&
                item.y <= maxCoordRef.current.y
              ) {
                oSelectElement({ node: item.attr });
              } else {
                oDeselectElement({ node: item.attr });
              }
            });
            break;
          }
          default:
            break;
        }
      },
      [
        ACTION_STATE,
        LINK_FORM_TYPES,
        actionRef,
        selectingRef,
        oMousePosSVG,
        oSelectElement,
        oDeselectElement,
      ]
    );

    const handleMouseDown = useCallback(
      (e) => {
        /** verifica se existe um arquivo na área de edição */
        if (svgRef.current) {
          selectingRef.current = true;

          const { shiftKey, ctrlKey, altKey } = e;

          const isLink = !!(
            e.target.nodeName === 'svg:circle' ||
            e.target.nodeName === 'svg:rect'
          );

          /** bloqueia movimento da imagem quando um link está sendo movido ou selecionado */
          if (isLink || ctrlKey) {
            setZoomCanMove(false);
          }

          /** Shift+Click */
          if (shiftKey && !ctrlKey && !altKey) {
            if (isLink) {
              actionRef.current = ACTION_STATE.select;
            }
          }

          /** Shift+Ctrl+Click Disponível */

          /** Shift+Alt+Click */
          if (shiftKey && !ctrlKey && altKey) {
            if (isLink) {
              actionRef.current = ACTION_STATE.deselect;
            }
          }

          /** Shift+Ctrl+Alt+Click Disponível */

          /** Ctrl+Click  */
          if (!shiftKey && ctrlKey && !altKey) {
            const m = oMousePosSVG(e);

            /** define valores iniciais para a área de seleção */
            startCoordRef.current.x = m.x;
            startCoordRef.current.y = m.y;
            selectorRef.current.setAttribute('x', 0);
            selectorRef.current.setAttribute('y', 0);
            selectorRef.current.setAttribute('width', 0);
            selectorRef.current.setAttribute('height', 0);
          }

          /** Ctrl+Alt+Click Disponível */

          /** Alt+Click Disponível  */
          if (!shiftKey && !ctrlKey && altKey) {
            /** armazena o elemento para o Alt+Click+Drag */
            if (isLink) {
              controlRef.current = e.target.parentElement;
            }
          }

          /** Click */
          if (!shiftKey && !ctrlKey && !altKey) {
            /** limpa seleção */
            oClearSelection();

            if (isLink) {
              controlRef.current = e.target.parentElement;
              actionRef.current = ACTION_STATE.select;
            }
          }

          switch (actionRef.current) {
            case ACTION_STATE.select:
              oSelectElement({ node: e.target.parentElement });
              break;
            case ACTION_STATE.deselect:
              oDeselectElement({ node: e.target.parentElement });
              break;
            default:
              break;
          }

          actionRef.current = null;
        }
      },
      [
        ACTION_STATE,
        actionRef,
        selectingRef,
        oSelectElement,
        oDeselectElement,
        oClearSelection,
        oMousePosSVG,
      ]
    );

    const handleMouseUp = useCallback(
      (e) => {
        if (selectingRef && selectingRef.current) {
          const m = oMousePosSVG(e);

          oClearSelector();

          switch (actionRef.current) {
            case ACTION_STATE.select:
              break;
            case ACTION_STATE.move:
            case ACTION_STATE.moveOne:
              /** atualiza posições do mapeamento OCR */
              if (ocrMappingRef.current && !!ocrMappingRef.current.length) {
                selectedRef.current.forEach((itemSelecionado) => {
                  const itemIndex = ocrMappingRef.current.findIndex(
                    (itemOCR) =>
                      String(itemOCR.attr.id) ===
                      String(itemSelecionado.node.id)
                  );

                  if (itemIndex >= 0) {
                    const { attr, ocr } = ocrMappingRef.current[itemIndex];

                    ocrMappingRef.current[itemIndex] = {
                      attr,
                      ocr: { ...ocr, x: m.x, y: m.y },
                    };
                  }
                });
              }

              /** verifica conflitos */
              oCheckConflict();
              break;
            default:
              break;
          }

          actionRef.current = null;
          selectingRef.current = false;

          /** desbloqueia movimento da imagem */
          setZoomCanMove(true);
        }
      },
      [ACTION_STATE, oMousePosSVG, oClearSelector, oCheckConflict]
    );

    const handleRemove = useCallback(() => {
      try {
        /** percorre elementos selecionados */
        selectedRef.current.forEach((s) => {
          /** remove elemento do mapeamento ORC */
          const mappingORCIndex = ocrMappingRef.current.findIndex(
            (m) => m.attr.id === s.node.id
          );

          if (mappingORCIndex !== -1)
            ocrMappingRef.current.splice(mappingORCIndex, 1);

          /** remove elemento do mapeamento principal */
          const mappingIndex = linkMappingRef.current.findIndex(
            (m) => m.id === s.node.id
          );

          if (mappingIndex !== -1)
            linkMappingRef.current.splice(mappingIndex, 1);

          /** verifica o nível para eliminar o elemento pai */
          oRemove(s.node).remove();
        });

        /** verifica associações */
        oCheckAssociation();

        /** verifica conflito */
        oCheckConflict();

        oClearSelection();
      } catch (err) {
        /** erro */
        toast.error(`Ops! Ocorreu um problema. ${err}`);
      }
    }, [
      selectedRef,
      ocrMappingRef,
      oRemove,
      oCheckAssociation,
      oCheckConflict,
      oClearSelection,
    ]);

    const handlePosition = useCallback(
      (x, y) => {
        const { baseVal } = rootRef.current.transform;
        const baseIndex = Array.from(baseVal).findIndex((e) => e.type === 1);

        let transformMatrix = null;
        if (baseIndex >= 0) {
          const { matrix } = baseVal.getItem(baseIndex);
          transformMatrix = matrix;
        }

        if (!transformMatrix)
          transformMatrix = svgRef.current.createSVGMatrix();

        // matrix translate
        transformMatrix.e = rootXRef.current + x;
        transformMatrix.f = rootYRef.current + y;

        rootXRef.current += x;
        rootYRef.current += y;

        const transform = baseVal.createSVGTransformFromMatrix(transformMatrix);
        baseVal.clear();
        baseVal.appendItem(transform);
      },
      [rootRef, svgRef, rootXRef, rootYRef]
    );

    const handleCenter = useCallback(
      (initial = false) => {
        const { baseVal } = rootRef.current.transform;
        const baseIndex = Array.from(baseVal).findIndex((e) => e.type === 1);

        let transformMatrix = null;
        if (baseIndex >= 0) {
          if (initial) return;

          const { matrix } = baseVal.getItem(baseIndex);
          transformMatrix = matrix;
        }

        if (!transformMatrix)
          transformMatrix = svgRef.current.createSVGMatrix();

        const {
          height: svgHeight,
          width: svgWidth,
        } = svgRef.current.getBoundingClientRect();

        const {
          height: rootHeight,
          width: rootWidth,
        } = rootRef.current.getBoundingClientRect();

        const svgPosX = Math.trunc(svgWidth / 2);
        const svgPosY = Math.trunc(svgHeight / 2);

        const rootPosX = Math.trunc(rootWidth / 2);
        const rootPosY = Math.trunc(rootHeight / 2);

        const x = svgPosX - rootPosX;
        const y = svgPosY - rootPosY;

        // translate
        transformMatrix.e = x;
        transformMatrix.f = y;

        rootXRef.current = x;
        rootYRef.current = y;

        const transform = baseVal.createSVGTransformFromMatrix(transformMatrix);
        baseVal.clear();
        baseVal.appendItem(transform);
      },
      [svgRef, rootRef]
    );

    const handleLandscape = useCallback(() => {
      const width = 874;
      const height = 614;
      const viewBox = `0 0 ${width} ${height}`;

      svgRef.current.setAttribute('width', width);
      svgRef.current.setAttribute('height', height);
      svgRef.current.setAttribute('viewBox', viewBox);
      svgRef.current.setAttribute('data-guid', 'landscape');

      /** centraliza automaticamente */
      if (pdfTool) {
        handleCenterPdfProcess();
      } else {
        handleCenter();
      }
    }, [handleCenter, handleCenterPdfProcess, pdfTool]);

    const handlePortrait = useCallback(() => {
      const width = 614;
      const height = 874;
      const viewBox = `0 0 ${width} ${height}`;

      svgRef.current.setAttribute('width', width);
      svgRef.current.setAttribute('height', height);
      svgRef.current.setAttribute('viewBox', viewBox);
      svgRef.current.setAttribute('data-guid', 'portrait');

      /** centraliza automaticamente */
      if (pdfTool) {
        handleCenterPdfProcess();
      } else {
        handleCenter();
      }
    }, [handleCenter, handleCenterPdfProcess, pdfTool]);

    const handleScale = useCallback(
      (value) => {
        const { baseVal } = rootRef.current.transform;
        const baseIndex = Array.from(baseVal).findIndex((e) => e.type === 1);

        let transformMatrix = null;
        if (baseIndex >= 0) {
          const { matrix } = baseVal.getItem(baseIndex);
          transformMatrix = matrix;
        }

        if (!transformMatrix)
          transformMatrix = svgRef.current.createSVGMatrix();

        // matrix scale
        transformMatrix.a = rootScaleRef.current + value;
        transformMatrix.d = rootScaleRef.current + value;

        rootScaleRef.current += value;

        const transform = baseVal.createSVGTransformFromMatrix(transformMatrix);
        baseVal.clear();
        baseVal.appendItem(transform);

        /** centraliza automaticamente */
        handleCenter();
      },
      [rootRef, svgRef, rootScaleRef, handleCenter]
    );

    const handleResize = useCallback(() => {
      const currentGuid = svgRef.current.getAttribute('data-guid');
      const currentWidth = svgRef.current.getAttribute('width');
      const currentHeight = svgRef.current.getAttribute('height');

      const width = currentGuid ? currentWidth : 614;
      const height = currentGuid ? currentHeight : 874;
      const dataGuid = currentGuid || 'portrait';

      const viewBox = `0 0 ${width} ${height}`;

      svgRef.current.setAttribute('width', width);
      svgRef.current.setAttribute('height', height);
      svgRef.current.setAttribute('viewBox', viewBox);
      svgRef.current.setAttribute('data-guid', dataGuid);
    }, []);

    /** define o tamanho do item para o valor passado */
    const handleLinkScale = useCallback(
      (valueH, valueW, link) => {
        /** define os elementos de texto e formato do item */
        const linkForm = link.childNodes[0];
        const linkText = link.childNodes[1];

        const { id: tipo = null } = linkForm.dataset || {};

        switch (Number(tipo)) {
          case LINK_FORM_TYPES.circle: {
            /** validação */
            const newSize = Number(valueH);
            const dh = newSize - Number(linkForm.getAttribute('r'));
            const xF = Number(linkForm.getAttribute('cx'));
            const yF = Number(linkForm.getAttribute('cy'));

            linkForm.setAttribute('r', newSize);
            linkForm.setAttribute('x', xF - dh / 2);
            linkForm.setAttribute('y', yF - dh / 2);

            linkText.setAttribute('font-size', `${newSize}px`);
            break;
          }
          case LINK_FORM_TYPES.rect: {
            const newSizeH = Number(valueH);
            const newSizeW = Number(valueW) || 0;

            const currWidth = Number(linkForm.getAttribute('width'));
            const currHeight = Number(linkForm.getAttribute('height'));

            const dh = currHeight - newSizeH;
            const dw = currWidth - newSizeW;
            const xF = Number(linkForm.getAttribute('x'));
            const yF = Number(linkForm.getAttribute('y'));

            linkForm.setAttribute('height', newSizeH);
            linkForm.setAttribute('width', newSizeW);

            linkForm.setAttribute('x', xF + dw / 2);
            linkForm.setAttribute('y', yF + dh / 2);

            /** atualiza tamanho do texto */
            linkText.setAttribute('font-size', `${Math.trunc(newSizeH / 2)}px`);

            break;
          }
          case LINK_FORM_TYPES.ocr:
            break;
          default:
            break;
        }

        /** verifica conflito */
        oCheckConflict();
      },
      [LINK_FORM_TYPES, oCheckConflict]
    );

    const handleLink = useCallback(() => {
      const uniq = uniqBy(table, 'idItem');
      uniq.forEach((item) => {
        oLink(item);
      });
      handleOCRLimpar();
    }, [table, oLink, handleOCRLimpar]);

    const handleRotate = useCallback((value) => {
      const { baseVal } = rootRef.current.transform;
      const i = Array.from(baseVal).findIndex((e) => e.type === 4);
      let rotate;

      if (i > 0) {
        rotate = baseVal.getItem(i);
      } else {
        rotate = svgRef.current.createSVGTransform();
        baseVal.appendItem(rotate);
      }

      rootRotateRef.current += value;
      if (rootRotateRef.current >= 360 || rootRotateRef.current < 0)
        rootRotateRef.current = 0;

      rotate.setRotate(
        rootRotateRef.current,
        rootXRef.current,
        rootYRef.current
      );
    }, []);

    const handleKeyDown = useCallback(
      (e) => {
        e.preventDefault();

        if (svgRef.current) {
          switch (e.keyCode) {
            /** 17: ctrl */
            case 17:
              break;
            /** 16: shift */
            case 16:
              break;
            /** 18: alt */
            case 18:
              break;
            /** 46: delete */
            case 46:
              if (selectedRef.current && selectedRef.current.length > 0)
                handleRemove();
              break;
            /** 37,38,39,40: arrow left, up, right, down */
            case 37:
              if (e.shiftKey) handlePosition(-10, 0);
              break;
            case 38:
              if (e.shiftKey) handlePosition(0, -10);
              break;
            case 39:
              if (e.shiftKey) handlePosition(10, 0);
              break;
            case 40:
              if (e.shiftKey) handlePosition(0, 10);
              break;
            case 83:
              /** Alt+S */
              if (e.altKey) {
                /** habilita edição de tamanho dos links */
                setTimeout(() => setSizeControl((state) => !state), 200);
              }
              break;
            case 90:
              /** Alt+Z */
              if (e.altKey) {
                /** habilita zoom de página */
                if (zoomControl && zoomResetRef.current) {
                  zoomResetRef.current.click();
                }
                setTimeout(() => setZoomControl((state) => !state), 200);
              }
              break;
            case 187:
              /** Shift+'=' */
              if (e.shiftKey) handleScale(0.1);
              break;
            case 189:
              /** Shift+'-' */
              if (e.shiftKey) handleScale(-0.1);
              break;
            default:
              break;
          }
        }
      },
      [zoomControl, handlePosition, handleScale, handleRemove]
    );

    /** limpa referências de controles */
    const handleKeyUp = useCallback(() => {
      if (svgRef.current) {
        selectingRef.current = false;

        if (actionRef.current) {
          actionRef.current = null;
        }

        if (controlRef.current) {
          controlRef.current = null;
        }
      }
    }, [svgRef, actionRef]);

    const handleControlClose = useCallback(
      (control) => {
        switch (control) {
          case 'zoomAnimControl':
            if (zoomControl && zoomResetRef.current) {
              zoomResetRef.current.click();
              setTimeout(() => setZoomControl((state) => !state), 200);
            }
            break;
          case 'sizeAnimControl':
            if (sizeControl) {
              setTimeout(() => setSizeControl((state) => !state), 200);
            }
            break;
          case 'all':
            if (zoomControl && zoomResetRef.current) {
              zoomResetRef.current.click();
              setTimeout(() => setZoomControl((state) => !state), 200);
            }
            if (sizeControl) {
              setTimeout(() => setSizeControl((state) => !state), 200);
            }
            break;
          default:
            break;
        }
      },
      [zoomControl, sizeControl]
    );

    const handleSizeEdit = useCallback(
      (valueH, valueW = 0, incremental = true) => {
        /** controle de alteração de tamanho */
        let newSizeH = 0;
        let newSizeW = 0;

        /** contagem de links alterados */
        let count = 0;

        /** verifica existencia de grupo de seleção */
        const selection = !!(selectedRef && selectedRef.current.length);

        /** altera o tamanho de todos os links conforme necessário */
        linkMappingRef.current.forEach((link) => {
          const isSelected = link.getAttribute('class')
            ? link.getAttribute('class').indexOf('selected') !== -1
            : false;

          /** não altera o tamanho se houver um grupo de seleção e este item não estiver selecionado */
          if (selection && !isSelected) return;

          /** altera apenas os tipos selecionados por padrão */
          if (
            !selection &&
            Number(link.childNodes[0].dataset.id) !== currLinkType
          )
            return;

          /** pega valor do atributo para incremental */
          switch (Number(link.childNodes[0].dataset.id)) {
            case LINK_FORM_TYPES.circle:
              newSizeH = valueH;
              newSizeW = 0;

              /** verifica se o processo é incremental */
              if (incremental)
                newSizeH += Number(link.childNodes[0].getAttribute('r'));

              /** validação */
              if (newSizeH < LINK_SIZE_LIMIT.min)
                newSizeH = LINK_SIZE_LIMIT.min;
              if (newSizeH > LINK_SIZE_LIMIT.max)
                newSizeH = LINK_SIZE_LIMIT.max;

              break;
            case LINK_FORM_TYPES.rect:
              newSizeH = valueH;
              newSizeW = valueW;

              /** atribui mesmo valor de "valueH" + "width" pois no processo incremental não preenche "valueW" */
              if (incremental) {
                const currSizeH = Number(
                  link.childNodes[0].getAttribute('height')
                );
                const currSizeW = Number(
                  link.childNodes[0].getAttribute('width')
                );

                newSizeH += currSizeH;
                newSizeW += currSizeW + valueH;

                /** proporcionalidade com o raio do circulo para situação incremental */
                if (newSizeH < currSizeH) newSizeH -= 1;
                if (newSizeW < currSizeW) newSizeW -= 1;

                if (newSizeH > currSizeH) newSizeH += 1;
                if (newSizeW > currSizeW) newSizeW += 1;
              }

              /** validação  */
              if (Math.trunc(newSizeH / 2) < LINK_SIZE_LIMIT.min) {
                newSizeH = LINK_SIZE_LIMIT.min * 2;
                newSizeW = LINK_SIZE_LIMIT.min * 2;
              }

              if (Math.trunc(newSizeH / 2) > LINK_SIZE_LIMIT.max) {
                newSizeH = LINK_SIZE_LIMIT.max * 2;
              }

              if (Math.trunc(newSizeW / 2) < LINK_SIZE_LIMIT.min)
                newSizeW = newSizeH;

              /** validação de comprimento somente para situação incremental */
              if (Math.trunc(newSizeW / 2) > LINK_SIZE_LIMIT.max) {
                if (incremental) newSizeW = newSizeH;
              }

              break;
            default:
              break;
          }

          count += 1;

          handleLinkScale(newSizeH, newSizeW, link, incremental);
        });

        if (!selection && count === linkMappingRef.current.length)
          oChangeDefLinkSize(newSizeH, newSizeW, true);
      },
      [
        LINK_FORM_TYPES,
        LINK_SIZE_LIMIT,
        currLinkType,
        handleLinkScale,
        oChangeDefLinkSize,
      ]
    );

    const handleConfirmar = useCallback(async () => {
      const { situacao } = catalogo;

      const confirmacao =
        situacao === opPaginaCatalogoSituacao.CONCLUIDO
          ? window.confirm(
              'Ao confirmar as alterações será necessário republicar o catálogo, deseja continuar?'
            )
          : true;

      if (confirmacao) {
        /** limpa biblioteca de localização OCR antes de salvar */
        handleOCRLimpar();
        /** carrega o arquivo editado */
        const blob = new Blob([svgRef.current.outerHTML], {
          type: 'image/svg+xml',
        });
        /** fecha controles do editor */
        handleControlClose('all');

        onSave({ svg: blob });
      }
    }, [catalogo, onSave, handleOCRLimpar, handleControlClose]);

    const handleCancelar = useCallback(() => {
      if (window.confirm('Deseja realmente cancelar as alterações?')) {
        setEditor(INITIAL_STATE);
        /** limpa biblioteca de localização OCR */
        handleOCRLimpar();
        /** fecha controles do editor */
        handleControlClose('all');
        setPdfTool(false);
        onCancel();
      }
    }, [INITIAL_STATE, onCancel, handleOCRLimpar, handleControlClose]);

    const editorSvg = useMemo(
      () => (
        <SVG
          id={`page-${editor.pageNum}`}
          src={editor.path ? editor.path : ''}
          tabIndex="0"
          useRequestCache={false}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          loading={() => <span>Loading</span>}
          fallback={() => <span>Selecione a imagem para editar</span>}
          beforeInjection={(svg) => {
            /** atribui component a variáveis */
            svgRef.current = svg;
          }}
          afterInjection={(err, svg) => {
            if (svg) {
              /** nó raiz */
              rootRef.current = Array.from(svg.childNodes).find(
                (e) => e.nodeName === 'svg:g'
              );
              /** limpa variáveis de alteração */
              rootXRef.current = 0;
              rootYRef.current = 0;
              rootScaleRef.current = 1;
              rootRotateRef.current = 0;

              /** cria o componente seletor e atribui ao SVG */
              const selector = document.createElementNS(
                rootRef.current.namespaceURI,
                'rect',
                rootRef.current
              );
              selector.setAttribute('id', 'selector');
              selector.setAttribute('stroke', '#ccc');
              selector.setAttribute('fill', 'rgba(0,0,0,.2)');
              rootRef.current.appendChild(selector);

              /** mapeamento dos elementos */
              linkMappingRef.current = [];
              mappingElement(svg.childNodes);

              /** verifica associações */
              oCheckAssociation();

              /** verifica conflitos */
              oCheckConflict();

              /** adicionar processo de arrastar e soltar no SVG */
              svg.ondragover = handleDragOverSVG;
              svg.ondrop = handleDropSVG;

              /** redimensiona automaticamente para o tamanho padrão */
              handleResize();

              selectorRef.current = selector;

              const { baseVal } = rootRef.current.transform;
              const baseIndex = Array.from(baseVal).findIndex(
                (e) => e.type === 1
              );

              if (baseIndex >= 0) {
                const { matrix } = baseVal.getItem(baseIndex);

                rootXRef.current = matrix.e;
                rootYRef.current = matrix.f;
                rootScaleRef.current = matrix.a;
                // rootRotateRef.current = 0;
              }

              /** centraliza automaticamente */
              handleCenter(true);
            }
          }}
        />
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        editor.pageNum,
        editor.path,
        // handleDropSVG,
        // handleDragOverSVG,
        // handleKeyDown,
        // handleKeyUp,
        // handleMouseDown,
        // handleMouseMove,
        // handleMouseUp,
        // mappingElement,
      ]
    );

    return (
      <Container id="editor" onDragOver={handleDragOver}>
        <ToolBar>
          <button title="Salvar" type="submit" onClick={handleConfirmar}>
            <MdDone size={16} />
          </button>
          <button type="button" title="Cancelar" onClick={handleCancelar}>
            <MdClose size={16} />
          </button>
          <button type="button" title="Remover" onClick={handleRemove}>
            <MdDelete size={16} />
          </button>
          <button type="button" title="Redimensionar" onClick={handleResize}>
            <MdCropFree size={16} />
          </button>
          <button
            type="button"
            title="Centralizar"
            onClick={() =>
              pdfTool ? handleCenterPdfProcess() : handleCenter()
            }
          >
            <MdCenterFocusStrong size={16} />
          </button>
          <button type="button" title="Horizontal" onClick={handleLandscape}>
            <MdStayPrimaryLandscape size={16} />
          </button>
          <button type="button" title="Vertical" onClick={handlePortrait}>
            <MdStayPrimaryPortrait size={16} />
          </button>
          <button
            type="button"
            title="Escala +"
            onClick={() =>
              pdfTool ? handleScalePdfProcess(0.1) : handleScale(0.1)
            }
          >
            <MdExposurePlus1 size={16} />
          </button>
          <button
            type="button"
            title="Escala -"
            onClick={() =>
              pdfTool ? handleScalePdfProcess(-0.1) : handleScale(-0.1)
            }
          >
            <MdExposureNeg1 size={16} />
          </button>
          <button
            type="button"
            title="Rotacionar"
            onClick={() => handleRotate(-45)}
          >
            <MdRotateLeft size={16} />
          </button>
          <button
            type="button"
            title="Rotacionar"
            onClick={() => handleRotate(45)}
          >
            <MdRotateRight size={16} />
          </button>
          <button
            type="button"
            title="Posiciona (Esquerda)"
            onClick={() =>
              pdfTool
                ? handlePositionPdfProcess(-10, 0)
                : handlePosition(-10, 0)
            }
          >
            <MdKeyboardArrowLeft size={16} />
          </button>
          <button
            type="button"
            title="Posiciona (Abaixo)"
            onClick={() =>
              pdfTool ? handlePositionPdfProcess(0, 10) : handlePosition(0, 10)
            }
          >
            <MdKeyboardArrowDown size={16} />
          </button>
          <button
            type="button"
            title="Posiciona (Acima)"
            onClick={() =>
              pdfTool
                ? handlePositionPdfProcess(0, -10)
                : handlePosition(0, -10)
            }
          >
            <MdKeyboardArrowUp size={16} />
          </button>
          <button
            type="button"
            title="Posiciona (Direita)"
            onClick={() =>
              pdfTool ? handlePositionPdfProcess(10, 0) : handlePosition(10, 0)
            }
          >
            <MdKeyboardArrowRight size={16} />
          </button>
          <button type="button" title="Link" onClick={handleLink}>
            <MdLink size={20} />
          </button>
          <button
            type="button"
            title="Link +"
            onClick={() => handleSizeEdit(+1)}
          >
            <MdUnfoldMore size={16} />
          </button>
          <button
            type="button"
            title="Link -"
            onClick={() => handleSizeEdit(-1)}
          >
            <MdUnfoldLess size={16} />
          </button>
          <button
            type="button"
            title="Link círculo"
            disabled={currLinkType === 0}
            onClick={() => {
              currLinkTypeRef.current = INITIAL_LINK_CIRCLE;
              setCurrLinkType(0);
              /** oculta para definir os campos */
              setSizeControl(false);

              oChangeDefLinkSize(0, 0, true);
            }}
          >
            <MdRadioButtonUnchecked size={16} />
          </button>
          <button
            type="button"
            title="Link retângulo"
            disabled={currLinkType === 1}
            onClick={() => {
              currLinkTypeRef.current = INITIAL_LINK_RECT;
              setCurrLinkType(1);
              /** oculta para definir os campos */
              setSizeControl(false);

              oChangeDefLinkSize(0, 0, true);
            }}
          >
            <MdCheckBoxOutlineBlank size={16} />
          </button>
          <button
            type="button"
            title="OCR - Tradicional"
            onClick={() =>
              handleOCRLocalizar(opEditorAssociarTipoExtracao.TRADICIONAL)
            }
          >
            <MdOutlineFilter1 size={16} />
          </button>
          {process.env.REACT_APP_EXPERIMENTAL_IA_ASSOCIACAO ===
            opSimNao.SIM && (
            <>
              <button
                type="button"
                title="OCR - Easy FAST"
                onClick={() =>
                  handleOCRLocalizar(opEditorAssociarTipoExtracao.EASY_FAST)
                }
              >
                <MdOutlineFilter2 size={16} />
              </button>
              <button
                type="button"
                title="ORC = Easy DEEP"
                onClick={() =>
                  handleOCRLocalizar(opEditorAssociarTipoExtracao.EASY_DEEP)
                }
              >
                <MdOutlineFilter3 size={16} />
              </button>
              <button
                type="button"
                title="Extração PDF"
                onClick={() =>
                  handlePDFLocalizar(opEditorAssociarTipoExtracao.PDF)
                }
              >
                <MdOutlineFilter4 size={16} />
              </button>
            </>
          )}
          <button type="button" title="Limpar OCR" onClick={handleOCRLimpar}>
            <MdOutlineLayersClear size={16} />
          </button>
        </ToolBar>

        <Wrapper
        // onMouseDown={handleMouseDown}
        // onMouseMove={handleMouseMove}
        // onMouseUp={handleMouseUp}
        // onKeyDown={handleKeyDown}
        // onKeyUp={handleKeyUp}
        >
          <TransformWrapper
            initialScale={1}
            centerOnInit
            centerZoomedOut
            panning={{ disabled: !zoomCanMove }}
            doubleClick={{
              step: 0.7,
              animationTime: 1000,
              animationType: 'easeInOutQuad',
            }}
            disabled={!zoomControl}
            onZoom={(target) => {
              zoomScaleRef.current = target.scale;
              return target;
            }}
          >
            {({
              zoomIn,
              zoomOut,
              resetTransform,
              // setTransform
            }) => (
              // if (elementToZoom) {
              //   setTransform(
              //     -Math.abs(elementToZoom.layerX),
              //     -Math.abs(elementToZoom.layerY),
              //     1.5,
              //     1000,
              //     'easeInOutQuad'
              //   );
              // }
              <EditorWrapper>
                {(zoomControl || sizeControl) && (
                  <ControlWrapper show={zoomControl || sizeControl}>
                    {zoomControl && (
                      <Control isVisible={zoomControl} control="zoom">
                        <Control.BtnClose
                          type="button"
                          onClick={() =>
                            handleControlClose(zoomRef && zoomRef.current.id)
                          }
                        >
                          <MdCancel size={16} />
                        </Control.BtnClose>
                        <Control.Animated ref={zoomRef} id="zoomAnimControl">
                          <Control.ZoomBtnAct
                            ref={zoomInRef}
                            type="button"
                            onClick={() => zoomIn()}
                          >
                            <MdZoomIn size={25} />
                          </Control.ZoomBtnAct>
                          <Control.ZoomBtnAct
                            ref={zoomOutRef}
                            type="button"
                            onClick={() => zoomOut()}
                          >
                            <MdZoomOut size={25} />
                          </Control.ZoomBtnAct>
                          <Control.ZoomBtnAct
                            ref={zoomResetRef}
                            type="button"
                            onClick={() => resetTransform()}
                          >
                            <MdYoutubeSearchedFor size={25} />
                          </Control.ZoomBtnAct>
                        </Control.Animated>
                      </Control>
                    )}
                    {sizeControl && (
                      <Control isVisible={sizeControl} control="size">
                        <Control.BtnClose
                          type="button"
                          onClick={() =>
                            handleControlClose(sizeRef && sizeRef.current.id)
                          }
                        >
                          <MdCancel size={16} />
                        </Control.BtnClose>
                        <Control.Animated ref={sizeRef} id="sizeAnimControl">
                          <Form
                            ref={formSizeRef}
                            onSubmit={({ sizeInputH, sizeInputW }) => {
                              handleSizeEdit(
                                Number(sizeInputH),
                                Number(sizeInputW),
                                false
                              );
                            }}
                            autoComplete="off"
                          >
                            <Control.SizeInput>
                              <Input
                                id="sizeInputH"
                                name="sizeInputH"
                                type="number"
                                placeholder="H"
                                width={60}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 &&
                                  formSizeRef.current.submitForm()
                                }
                              />
                              <Input
                                id="sizeInputW"
                                name="sizeInputW"
                                type="number"
                                placeholder="W"
                                width={60}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 &&
                                  formSizeRef.current.submitForm()
                                }
                              />
                            </Control.SizeInput>
                          </Form>
                        </Control.Animated>
                      </Control>
                    )}
                  </ControlWrapper>
                )}
                <TransformComponent>{editorSvg}</TransformComponent>
              </EditorWrapper>
            )}
          </TransformWrapper>
        </Wrapper>
      </Container>
    );
  }
);

export default EditorArea;

EditorArea.propTypes = {
  catalogo: PropTypes.instanceOf(Object).isRequired,
  pagina: PropTypes.instanceOf(Object),
  itensPagina: PropTypes.arrayOf(Object),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onBeforeOCR: PropTypes.func,
  onAfterOCR: PropTypes.func,
  onAssociate: PropTypes.func,
  onLinkHighlight: PropTypes.func,
};

EditorArea.defaultProps = {
  pagina: null,
  itensPagina: null,
  onBeforeOCR: null,
  onAfterOCR: null,
  onAssociate: null,
  onLinkHighlight: null,
};
