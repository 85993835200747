import { AppError } from '../errors/AppError';
import { opPaginaCatalogoOrientacao, opDimensaoSvgGeradoCDI } from './const';

const LINK_FORM_TYPES = {
  ocr: 2,
};

export const getSvgCoordinates = (selectorRef) => {
  const x = selectorRef.current.x.baseVal.value;
  const y = selectorRef.current.y.baseVal.value;
  const width = selectorRef.current.width.baseVal.value;
  const height = selectorRef.current.height.baseVal.value;

  return { x, y, width, height };
};

export const createOcrGroup = (rootRef) => {
  /** cria grupo para mapeamento do serviço de OCR */
  const ocrMapper = document.createElementNS(
    rootRef.current.namespaceURI,
    'svg:g',
    rootRef.current
  );
  ocrMapper.setAttribute('id', 'ocrGroup');
  rootRef.current.appendChild(ocrMapper);
  return ocrMapper;
};

export const createSvgLinkPdfProcess = (
  svgNamespace,
  ocrMapperRef,
  pt,
  index
) => {
  /** cria elemento */
  const link = document.createElementNS(svgNamespace, 'a');
  link.id = index;
  link.setAttribute('x', pt.center_x);
  link.setAttribute('y', pt.center_y - 5);
  link.setAttribute('class', '');
  // código preventivo para o link não ser executado no design
  link.addEventListener('click', (e) => e.preventDefault());
  ocrMapperRef.current.appendChild(link);

  /** cria retângulo ocr */
  const linkForm = document.createElementNS(svgNamespace, 'svg:rect');
  linkForm.setAttribute('x', pt.center_x + 5.5);
  linkForm.setAttribute('y', pt.center_y - 5);
  linkForm.setAttribute('height', '10px');
  linkForm.setAttribute('width', `${pt.text.length * 6}px`);
  linkForm.setAttribute('class', 'success');
  linkForm.setAttribute('data-id', LINK_FORM_TYPES.ocr);
  link.appendChild(linkForm);

  /** cria texto */
  const linkText = document.createElementNS(svgNamespace, 'svg:text');
  linkText.setAttribute('x', pt.center_x + 5.5);
  linkText.setAttribute('y', pt.center_y + 3);
  linkText.setAttribute('font-size', '10px');
  linkText.setAttribute('class', 'success');
  linkText.textContent = pt.text;
  link.appendChild(linkText);

  return link;
};

export const centerCalculation = (orientacaoImagem, x, y, width, height) => {
  let centroX;
  let centroY;

  switch (orientacaoImagem) {
    case opPaginaCatalogoOrientacao.LANDSCAPE:
      centroX = opDimensaoSvgGeradoCDI.LARGURA_PAISAGEM / 2 - (width / 2 + x);
      centroY = opDimensaoSvgGeradoCDI.ALTURA_PAISAGEM / 2 - (height / 2 + y);

      break;
    case opPaginaCatalogoOrientacao.PORTRAIT:
      centroX = opDimensaoSvgGeradoCDI.LARGURA_RETRATO / 2 - (width / 2 + x);
      centroY = opDimensaoSvgGeradoCDI.ALTURA_RETRATO / 2 - (height / 2 + y);
      break;
    default:
      throw new AppError('Orientação de imagem inválida');
  }

  return { centroX, centroY };
};

export const updateSvgPositionProcessPdf = (
  svgRef,
  rootRef,
  coordX,
  coordY
) => {
  let transformMatrix = svgRef.current.createSVGMatrix();
  const { baseVal } = rootRef.current.transform;

  const baseIndex = Array.from(baseVal).findIndex((e) => e.type === 1);
  if (baseIndex >= 0) {
    const { matrix } = baseVal.getItem(baseIndex);
    transformMatrix = matrix;
  }

  transformMatrix.e += coordX;
  transformMatrix.f += coordY;

  const transform = baseVal.createSVGTransformFromMatrix(transformMatrix);
  baseVal.clear();
  baseVal.appendItem(transform);
};

export const centerSvgPdfProcess = (
  svgRef,
  rootRef,
  centralizacaoX,
  centralizacaoY
) => {
  let transformMatrix = svgRef.current.createSVGMatrix();
  const { baseVal } = rootRef.current.transform;

  const baseIndex = Array.from(baseVal).findIndex((e) => e.type === 1);
  if (baseIndex >= 0) {
    const { matrix } = baseVal.getItem(baseIndex);
    transformMatrix = matrix;
  }

  transformMatrix.e = centralizacaoX;
  transformMatrix.f = centralizacaoY;

  const transform = baseVal.createSVGTransformFromMatrix(transformMatrix);

  baseVal.clear();
  baseVal.appendItem(transform);
};

export const scaleSvgProcessPdf = (svgRef, rootRef, rootScaleRef, valor) => {
  let transformMatrix = svgRef.current.createSVGMatrix();
  const { baseVal } = rootRef.current.transform;

  const baseIndex = Array.from(baseVal).findIndex((e) => e.type === 1);
  if (baseIndex >= 0) {
    const { matrix } = baseVal.getItem(baseIndex);
    transformMatrix = matrix;
  }

  transformMatrix.a = rootScaleRef.current + valor;
  transformMatrix.d = rootScaleRef.current + valor;

  rootScaleRef.current += valor;

  const transform = baseVal.createSVGTransformFromMatrix(transformMatrix);
  baseVal.clear();
  baseVal.appendItem(transform);
};
