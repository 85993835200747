import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  MdSearch,
  MdYoutubeSearchedFor,
  MdZoomIn,
  MdZoomOut,
  MdFullscreen,
  MdFullscreenExit,
  MdPrint,
  MdChevronLeft,
  MdChevronRight,
  MdClose,
  MdEmail,
  MdHome,
  // MdStar,
  MdStarOutline,
  MdPictureAsPdf,
} from 'react-icons/md';

import { GiSandsOfTime } from 'react-icons/gi';

import { components } from 'react-select';

// import { border, borderRadius } from 'polished';
// import { borderRadius, padding } from 'polished';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import {
  opEmpresa,
  // opSimNao,
  opTipoAcao,
  opTipoAcesso,
} from '../../../lib/const';
import { debounce } from '../../../lib/inputUtils';
import { initialParams } from '../../../lib/reactRouter';
import { getMaterial, getArranjo } from '../../../lib/asyncUtils';

import TreeView from '../../../components/TreeView';

import { Form } from '../../../components/Form';
import AsyncSelect from '../../../components/Form/Input/AsyncSelect';
import { Info } from '../../../components/Info';

import Listagem from './Listagem';
import Page from './Pagina';

import {
  Container,
  Wrapper,
  Menu,
  Content,
  WrapperTitleInfo,
  WrapperSubtitles,
  WrapperTittleInfoSubtitle,
  BtnFilter,
} from './styles';
import {
  handleHomeFilter,
  handleHomeFilterClear,
} from '../../../store/modules/homeFilter/actions';
import HoverImagePortal from '../../../components/HoverImagePortal';
import {
  adicionaCarrinhoArrayRequest,
  sharedManagementSet,
} from '../../../store/modules/cart/actions';

function Classificacao() {
  const { id } = useParams();
  const location = useLocation();

  const { app, action, signed, token } = useSelector(
    (state) => state.auth || {}
  );
  const { adm, empresa } = useSelector((state) => state.user.profile || {});
  const { homeFilter } = useSelector((state) => state || {});
  const { sharedManagement } = useSelector((state) => state.cart || {});

  /** referencia para atribuir controles de zoom */
  const zoomInRef = useRef();
  const zoomOutRef = useRef();
  const zoomResetRef = useRef();

  const formRef = useRef();
  const paginaRef = useRef();

  const refTtreeView = useRef(null);

  const dispatch = useDispatch();

  const [menuHidden, setMenuHidden] = useState(app);
  const [pageMaximize, setPageMaximize] = useState(false);

  const [catalogo, setCatalogo] = useState(null);
  const [paginaCatalogo, setPaginaCatalogo] = useState([]);
  const [controle, setControle] = useState(false);
  const [shownPage, setShownPage] = useState(0);
  const [nSerie, setNSerie] = useState([]);
  const [catalogoPdf, setCatalogoPdf] = useState(null);

  const [loadingButtonPrint, setLoadingButtonPrint] = useState(false);
  const [loadingButtonMail, setLoadingButtonMail] = useState(false);

  // const [searchHandleState, setSearchHandleState] = useState(opSimNao.SIM);
  const [fmtFilterSearch, setFmtFilterSearch] = useState([]);

  const [valueAsyncSelect, setValueAsyncSelect] = useState(null);

  const [isHovered, setIsHovered] = useState(null);

  useEffect(() => {
    api.get(`/catalogo-navegacao/${id}/pagina`).then((response) => {
      setPaginaCatalogo(response.data);
    });
  }, [id]);

  useEffect(() => {
    if (Array.isArray(homeFilter.paginas) && homeFilter.paginas.length > 0) {
      setFmtFilterSearch(
        paginaCatalogo.filter((i) =>
          homeFilter.paginas.some((j) => i.idPagina === j.idPagina)
        )
      );
    } else {
      setFmtFilterSearch(paginaCatalogo);
    }
  }, [paginaCatalogo, homeFilter]);

  useEffect(() => {
    /** pega parâmetros da rota */
    const paramQuery = new URLSearchParams(location.search);

    const paramRoute = {
      ...initialParams,
      ...Object.fromEntries(paramQuery),
    };

    api
      .get(`/catalogo-navegacao/${id}`, {
        params: {
          ...paramRoute,
        },
      })
      .then((response) => {
        setCatalogo(response.data);

        /** Preenchimento do campos arranjo */
        const { arranjo } = response.data;

        if (arranjo && formRef.current) {
          const valorAtual = formRef.current.getFieldValue('idArranjo');
          if (!valorAtual) {
            formRef.current.setFieldValue('idArranjo', {
              label: `N/S: ${arranjo.numeroSerie} - ${arranjo.nome}`,
              value: `${arranjo.idSerie}.${arranjo.idArranjo}`,
            });
          }
        }
      })
      .catch((err) => {
        AppError(err);
      });
  }, [id, location]);

  useEffect(() => {
    api
      .get(`/catalogo/${id}`)
      .then((response) => {
        setCatalogoPdf(response.data.arquivo.url);
        setNSerie(response.data.series || []);
      })
      .catch((err) => {
        AppError(err);
      });
  }, [id]);

  useEffect(() => {
    /** pega parâmetros da rota */
    const paramQuery = new URLSearchParams(location.search);

    setControle(paramQuery.has('IDP'));
    if (catalogo && paramQuery.has('IDP')) {
      const page = Number(paramQuery.get('IDP'));

      const { paginas = [] } = catalogo;

      const index = paginas.findIndex((p) => p === page);
      if (index >= 0) setShownPage(index + 1);

      if (homeFilter.value !== null) {
        const indexHomeFilter = fmtFilterSearch.findIndex(
          (p) => p.idPagina === page
        );
        if (indexHomeFilter >= 0) setShownPage(indexHomeFilter + 1);
      }
    }
  }, [location, catalogo, homeFilter, fmtFilterSearch]);

  const handleFiltroAsyncSelect = useCallback(
    (value) => {
      api
        .get(`/catalogo-filtro/${id}/material-catalogo`, {
          params: {
            q: value,
          },
        })
        .then((response) => {
          const { searchHandle, paginas, paginasERP } = response.data;

          // console.log(value);

          dispatch(handleHomeFilter(value, searchHandle, paginas, paginasERP));
        })
        .finally(() => {
          setValueAsyncSelect(null);
          history.push(`/catalogo.partes/${id}`);
        });

      // history.push(`/catalogo.partes/${id}`);
    },
    [id, dispatch]
  );

  // const handleFiltroPaginas = useCallback(() => {
  //   // const { value, searchHandle, paginas, paginasERP } = homeFilter;

  //   // console.log(value, searchHandle, paginas, paginasERP);

  //   // levar para pagina de busca das páginas com o value pesquisado
  //   // history.push(`/catalogo.partes/${id}/paginas-filtro`);

  //   console.log('TESTE');
  // }, [history, id]);

  const handlePagina = useCallback(
    (data) => {
      const { value = '' } = data || {};
      const [, idPagina] = value.split('.');

      if (idPagina) {
        const { niveis = [] } =
          paginaCatalogo.find((p) => p.idPagina === Number(idPagina)) || {};

        const searchLocation = [];

        /** processa os níveis da página */
        niveis.forEach((itemNivel) => {
          searchLocation.push(`IDN_${itemNivel.codigo}=${itemNivel.id}`);
        });

        /** processa página */
        searchLocation.push(`IDP=${idPagina}`);
        /** processa item */
        searchLocation.push(`IDI=${data.value}`);

        /** DEVE SER UMA FORMA DE BOTAO PARA TRAZER SOMENTE AS PAGINAS QUE CONTEM */

        // if (homeFilter.searchHandle === 1 || homeFilter.searchHandle === null) {
        //   // Criar rota de busca das paginas filtradas
        //   history.push(`/catalogo.partes/${id}`);
        // } else {
        //   /** monta rota para redirecionamento */
        //   history.push(`${id}?${searchLocation.join('&')}`);
        // }

        history.push(`${id}?${searchLocation.join('&')}`);
      }
    },
    [id, paginaCatalogo]
  );

  const handleSerieArranjo = useCallback(
    (data) => {
      const params = [];

      /** separa valores "serie.arranjo" */
      const { value = '' } = data || {};
      const [serie, arranjo] = value.split('.');

      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);
      const paramRoute = {
        ...initialParams,
        ...Object.fromEntries(paramQuery),
      };

      /** processa os parâmetros de sistema e local caso eles existam no primeiro ciclo */
      const { FLT_IDS: fltIdS, FLT_IDL: fltIdL } = paramRoute;
      if (fltIdS) params.push(`FLT_IDS=${fltIdS}`);
      if (fltIdL) params.push(`FLT_IDL=${fltIdL}`);

      /** processa arranjo */
      if (serie && arranjo) {
        params.push(`FLT_IDE=${serie}`);
        params.push(`FLT_IDA=${arranjo}`);
      }

      /** monta rota para redirecionamento */
      const routeParam = `?${params.join('&')}`;

      if (location.search !== routeParam)
        history.push(location.pathname + routeParam);
    },
    [location]
  );

  // const handlePageNavFilter = useCallback((step) => {
  //   if (homeFilter.value !== null) {
  //     const currentPage = paginaCatalogo[shownPage - 1];

  //     console.log(currentPage);

  //     const indexCurrentPage = fmtFilterSearch.indexOf(
  //       fmtFilterSearch.find((i) => i.idPagina === currentPage.idPagina)
  //     );

  //     console.log('indexCurrentPage...', indexCurrentPage);
  //     console.log(
  //       'fmtFilterSearch[indexCurrentPage + step]...',
  //       fmtFilterSearch[indexCurrentPage + step]
  //     );

  //     if (fmtFilterSearch[indexCurrentPage + step] === undefined) return;

  //     const { idPagina, niveis } = fmtFilterSearch[indexCurrentPage + step];
  //     const { idCatalogo } = catalogo;

  //     const searchLocation = [];

  //     niveis.forEach((itemNivel) => {
  //       searchLocation.push(`IDN_${itemNivel.codigo}=${itemNivel.id}`);
  //     });

  //     searchLocation.push(`IDP=${idPagina}`);

  //     history.push(`${idCatalogo}?${searchLocation.join('&')}`);
  //   }
  // }, []);

  const handlePageNav = useCallback(
    (step) => {
      if (catalogo && step !== 0) {
        const nextPage = shownPage - 1 + step;

        // console.log('\n paginaCatalogo...', paginaCatalogo);
        // console.log('homeFilter...', homeFilter);
        // console.log('fmtFilterSearch...', fmtFilterSearch);

        // console.log('shownPage, step...', shownPage, step);
        // console.log(
        //   'nextPage, fmtFilterSearch.length...',
        //   nextPage,
        //   fmtFilterSearch.length
        // );

        if (homeFilter.value !== null) {
          const currentPage = fmtFilterSearch[shownPage - 1];

          // console.log(currentPage);

          const indexCurrentPage = fmtFilterSearch.indexOf(
            fmtFilterSearch.find((i) => i.idPagina === currentPage.idPagina)
          );

          // console.log('indexCurrentPage...', indexCurrentPage);
          // console.log(
          //   'fmtFilterSearch[indexCurrentPage + step]...',
          //   fmtFilterSearch[indexCurrentPage + step]
          // );

          if (fmtFilterSearch[indexCurrentPage + step] === undefined) return;

          const { idPagina, niveis } = fmtFilterSearch[indexCurrentPage + step];
          const { idCatalogo } = catalogo;

          const searchLocation = [];

          niveis.forEach((itemNivel) => {
            searchLocation.push(`IDN_${itemNivel.codigo}=${itemNivel.id}`);
          });

          searchLocation.push(`IDP=${idPagina}`);

          history.push(`${idCatalogo}?${searchLocation.join('&')}`);
        }

        /** Navega para a página encontrada */
        if (
          homeFilter.value === null &&
          nextPage >= 0 &&
          nextPage < paginaCatalogo.length
        ) {
          const { idPagina, niveis } = paginaCatalogo[nextPage];

          const { idCatalogo, paginas = [] } = catalogo;

          /** verifica se a página não está filtrada */
          if (paginas.find((p) => p === idPagina)) {
            const searchLocation = [];

            /** pega parâmetros da rota */
            const paramQuery = new URLSearchParams(location.search);
            const paramRoute = {
              ...initialParams,
              ...Object.fromEntries(paramQuery),
            };

            /** processa os parâmetros de sistema, local e pesquisa caso eles existam no primeiro ciclo */
            const {
              FLT_IDS: fltIdS,
              FLT_IDL: fltIdL,
              FLT_IDE: fltIdE,
              FLT_IDA: fltIdA,
              q: fltSearch,
            } = paramRoute;
            if (fltIdS) searchLocation.push(`FLT_IDS=${fltIdS}`);
            if (fltIdL) searchLocation.push(`FLT_IDL=${fltIdL}`);
            if (fltIdE) searchLocation.push(`FLT_IDE=${fltIdE}`);
            if (fltIdA) searchLocation.push(`FLT_IDA=${fltIdA}`);
            if (fltSearch) searchLocation.push(`q=${fltSearch}`);

            /** processa os níveis da página */
            niveis.forEach((itemNivel) => {
              searchLocation.push(`IDN_${itemNivel.codigo}=${itemNivel.id}`);
            });

            /** processa página */
            searchLocation.push(`IDP=${idPagina}`);

            /** monta rota para redirecionamento */
            history.push(`${idCatalogo}?${searchLocation.join('&')}`);
          }
        }
      }
    },
    [location, catalogo, paginaCatalogo, shownPage, fmtFilterSearch, homeFilter]
  );

  // const handleImprimir = useCallback(() => {
  //   /** chama função de impressão do componente filho */
  //   if (paginaRef.current) {
  //     paginaRef.current.handleImprimirPagina();
  //   }
  // }, []);

  const handlePDF = useCallback(async () => {
    setLoadingButtonPrint(true);
    /** chama função de impressão do componente filho */
    if (paginaRef.current) {
      paginaRef.current.handleImprimirPagina(false, setLoadingButtonPrint);
    }
  }, []);

  const handleMail = useCallback(() => {
    setLoadingButtonMail(true);
    /** chama função de impressão do componente filho */
    if (paginaRef.current) {
      paginaRef.current.handleImprimirPagina(true, setLoadingButtonMail);
    }
  }, []);

  const handleRedimensionar = useCallback(() => {
    setPageMaximize((state) => !state);

    /** chama função de redimensionamento do componente filho */
    if (paginaRef.current) {
      paginaRef.current.handleRedimensionarPagina(!pageMaximize);
    }
  }, [pageMaximize]);

  const handleFiltro = useCallback(
    (key) => {
      dispatch(handleHomeFilterClear());
      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);

      const params = [];
      paramQuery.forEach((param, index) => {
        if (index !== key) {
          params.push(`${index}=${param}`);
        }
      });

      const routeParam = `?${params.join('&')}`;

      history.push(location.pathname + routeParam);
    },
    [location, dispatch]
  );

  const handleBreadcrumb = useCallback(
    (data) => {
      const breadcrumbs = [];
      const url = [];

      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);
      const paramRoute = {
        ...initialParams,
        ...Object.fromEntries(paramQuery),
      };

      /** processa os parâmetros de sistema, local e pesquisa caso eles existam */
      const {
        FLT_IDS: fltIdS,
        FLT_IDL: fltIdL,
        FLT_IDE: fltIdE,
        FLT_IDA: fltIdA,
        q: fltSearch,
      } = paramRoute;
      if (fltIdS) url.push(`FLT_IDS=${fltIdS}`);
      if (fltIdL) url.push(`FLT_IDL=${fltIdL}`);
      if (fltIdE) url.push(`FLT_IDE=${fltIdE}`);
      if (fltIdA) url.push(`FLT_IDA=${fltIdA}`);
      if (fltSearch) url.push(`q=${fltSearch}`);

      const { idCatalogo, nome, treeView } = data;

      breadcrumbs.push({
        name: nome,
        url:
          url.length > 0 ? `${idCatalogo}?${url.join('&')}` : `${idCatalogo}`,
      });

      let currNode = treeView;

      Object.keys(paramRoute).forEach((paramAtual) => {
        /** monta breadcrumb */
        if (paramAtual.startsWith('IDN_') || paramAtual.startsWith('IDP')) {
          const [, key] = paramAtual.split('_');

          /** monta url para acesso */
          url.push(`${paramAtual}=${paramRoute[paramAtual]}`);

          const itemNode = currNode.find(
            (n) =>
              n.codigo === (Number(key) || idCatalogo) &&
              n.id === Number(paramRoute[paramAtual])
          );

          if (itemNode) {
            const { descricao } = itemNode;

            breadcrumbs.push({
              name: descricao,
              url: `?${url.join('&')}`,
            });

            /** atualiza item da treeview */
            if (itemNode.itens) currNode = itemNode.itens;
          }
        }
      });

      const { name = '' } = breadcrumbs[breadcrumbs.length - 1] || {};

      // console.log(homeFilter);

      return (
        <Content.ToolBar.Header>
          {homeFilter.value && (
            <div>
              <h5>Filtrado por: </h5>
              <span>{homeFilter.value}</span>
              <button
                type="button"
                title="Limpar filtro"
                onClick={() => {
                  dispatch(handleHomeFilterClear());
                  history.push(`/catalogo.partes/${id}`);
                }}
              >
                <MdClose size={20} />
              </button>
            </div>
          )}
          <h3>{name}</h3>
          {breadcrumbs.map((bread, index, array) => {
            const label =
              array.length - 1 === index ? bread.name : `${bread.name} / `;

            return (
              <Link
                key={index}
                to={bread.url}
                onClick={() => dispatch(handleHomeFilterClear())}
              >
                {label}
              </Link>
            );
          })}
        </Content.ToolBar.Header>
      );
    },
    [location, dispatch, homeFilter, id]
  );

  const handleContainer = useCallback(
    (data) => {
      const { idCatalogo } = data;

      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);

      // console.log(location.search);
      // console.log(data);

      return paramQuery.has('IDP') ? (
        <Page
          ref={paginaRef}
          id={idCatalogo}
          idPagina={Number(paramQuery.get('IDP'))}
          zoomInRef={zoomInRef}
          zoomOutRef={zoomOutRef}
          zoomResetRef={zoomResetRef}
        />
      ) : (
        <Listagem data={data} />
      );
    },
    [location]
  );

  useEffect(() => {
    async function addCart(output, idCatalogo) {
      return dispatch(adicionaCarrinhoArrayRequest(idCatalogo, output));
    }
    async function fetchData() {
      try {
        console.log(token);
        const response = await api.get(`app.session/${token}`);
        const { data } = response;

        const { info, idCatalogo } = data;

        const fmtOutputInfo = [];

        if (info) {
          const fmtInfo = info.split('&');

          fmtInfo.forEach((i, index) => {
            if (i.includes('IDPAG_')) {
              fmtOutputInfo.push({
                idPagina: Number(
                  fmtInfo[index].split('=')[1].replaceAll(',', '')
                ),
                idItem: String(
                  fmtInfo[index + 1].split('=')[1].replaceAll(',', '')
                ),
                idMaterial: Number(
                  fmtInfo[index + 2].split('=')[1].replaceAll(',', '')
                ),
                codimate:
                  String(
                    fmtInfo[index + 3].split('=')[1].replaceAll(',', '')
                  ) !== 'undefined'
                    ? String(
                        fmtInfo[index + 3].split('=')[1].replaceAll(',', '')
                      )
                    : null,
                quantidade: Number(
                  fmtInfo[index + 4].split('=')[1].replaceAll(',', '')
                ),
                imagemCatalogo: String(
                  fmtInfo[index + 5].split('=')[1].replaceAll(',', '')
                ),
              });
            }
          });
        }

        await addCart(fmtOutputInfo, idCatalogo);

        if (info && !sharedManagement) {
          dispatch(sharedManagementSet(true));
          toast.info('Existem itens no carrinho que foram compartilhados!');
          history.push(`/carrinho`);
        }
      } catch (error) {
        toast.error(`Erro ao carregar o compartilhamento ${error}`);
      }
    }

    fetchData();
  }, [token, dispatch, sharedManagement]);

  const Group = (props) => <components.Group {...props} />;

  // console.log(homeFilter);
  // console.log(catalogo);

  const handleScrollLinkTreeView = () => {
    const links = refTtreeView.current.querySelectorAll('a');

    links.forEach((elemento) => {
      const linkSelecionado = elemento.getAttribute('data-selected');
      if (linkSelecionado === 'true') {
        setTimeout(() => {
          elemento.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }, 1000);
      }
    });
  };

  return (
    <>
      <Container>
        {catalogo && (
          <>
            <Container.Header>
              {[opTipoAcao.PLATAFORMA, opTipoAcao.INTEGRACAO_CONSULTA].includes(
                action
              ) && (
                <Container.Header.BtnBack
                  title="Voltar para a Home"
                  onClick={() => {
                    dispatch(handleHomeFilterClear());
                    history.push('/');
                  }}
                >
                  <MdHome size={30} />
                </Container.Header.BtnBack>
              )}
              {empresa.idEmpresa === opEmpresa.DMB && (
                <Container.Header.DownloadPdf
                  title="Download catálogo pdf completo"
                  href={catalogoPdf}
                  target="_blank"
                >
                  <MdPictureAsPdf size={30} />
                </Container.Header.DownloadPdf>
              )}
              <WrapperTittleInfoSubtitle>
                <WrapperTitleInfo>
                  <Container.Header.Title>
                    {catalogo.nome}

                    {(catalogo || catalogo.arquivo) && (
                      <Info.Root topIcon="0" rightIcon="-5px">
                        {nSerie && nSerie.length > 0 && (
                          <div>
                            <strong>S/N: </strong>
                            {nSerie.map((item) => item.numeroSerie).join(',')}
                          </div>
                        )}
                        {catalogo.arquivo.fabricante && (
                          <div>
                            <strong>Fabricante:</strong>
                            {catalogo.arquivo.fabricante.descricao}
                          </div>
                        )}
                        {catalogo.arquivo.modelos &&
                          catalogo.arquivo.modelos.length > 0 && (
                            <div>
                              <strong>Modelo:</strong>
                              {catalogo.arquivo.modelos
                                .map((item) => item.descricao)
                                .join(',')}
                            </div>
                          )}
                      </Info.Root>
                    )}
                  </Container.Header.Title>
                </WrapperTitleInfo>
                <WrapperSubtitles>
                  <Container.Header.Subtitle>
                    {catalogo.arquivo.fabricante.descricao}
                  </Container.Header.Subtitle>
                  <Container.Header.Subtitle>-</Container.Header.Subtitle>
                  <Container.Header.Subtitle>
                    {catalogo.arquivo.modelos
                      .map((item) => item.descricao)
                      .join(' | ')}
                  </Container.Header.Subtitle>
                </WrapperSubtitles>
              </WrapperTittleInfoSubtitle>

              {[opTipoAcao.INTEGRACAO_CATALOGO].includes(action) &&
                [opTipoAcesso.CLIENTE].includes(Number(adm)) &&
                signed && (
                  <Container.Header.BtnFavorite title="Solicitar ao fornecedor para manter o acesso ao catálogo">
                    <MdStarOutline size={25} />
                  </Container.Header.BtnFavorite>
                )}
              <Container.Header.Search>
                <Form.Row>
                  <Form ref={formRef}>
                    <div
                      style={{
                        position: 'relative',
                      }}
                    >
                      <Info.Root rightIcon="470px" topIcon="7px">
                        <div>
                          <span id="grey-italic-span">
                            <strong id="white-bold-span">
                              Partnumber - Descrição |{' '}
                            </strong>
                            Sistema <strong id="white-bold-span">| </strong>
                            Níveis / ...
                          </span>
                        </div>
                        <div>
                          <span id="grey-italic-padding-span">OU</span>
                        </div>
                        <div>
                          <span id="grey-italic-span">
                            <strong id="white-bold-span">
                              Codigo ERP - Descrição |{' '}
                            </strong>
                            Sistema <strong id="white-bold-span">| </strong>
                            Níveis / ...
                          </span>
                        </div>
                      </Info.Root>
                    </div>
                    {catalogo.arranjoPagina && (
                      <AsyncSelect
                        id="idArranjo"
                        name="idArranjo"
                        placeholder="Digite para prequisar um arranjo..."
                        isClearable
                        cacheOptions
                        defaultOptions
                        loadOptions={(value, cb) =>
                          getArranjo({ id, value }, cb)
                        }
                        onChange={(data) => handleSerieArranjo(data)}
                        width={270}
                      />
                    )}
                    <AsyncSelect
                      id="idMaterial"
                      name="idMaterial"
                      placeholder="Digite um termo, partnumber ou código ERP para pesquisar..."
                      isClearable
                      cacheOptions={false}
                      defaultOptions
                      loadOptions={(value, cb) => {
                        setValueAsyncSelect(value);
                        getMaterial({ id, value }, cb);
                      }}
                      components={{ Group }}
                      onChange={(data) => {
                        handlePagina(data);
                      }}
                      formatOptionLabel={(option, { context }) => {
                        const imageUrl = option.label.caminho;
                        // const [isHovered, setIsHovered] = useState(false);

                        // Apenas se o Select estiver aberto (context === 'menu')
                        return (
                          <div className="container-async-select">
                            {context === 'menu' ? (
                              <>
                                <div
                                  className="hover-container-async-select"
                                  onMouseEnter={() => setIsHovered(option)}
                                  onMouseLeave={() => setIsHovered(null)}
                                >
                                  <img
                                    id="img-async-select"
                                    src={imageUrl}
                                    alt={option.label.descricaoPagina}
                                  />
                                  {/* Renderizar a imagem fora do menu usando o Portal */}
                                  <HoverImagePortal
                                    option={option}
                                    src={imageUrl}
                                    alt={option.label.descricaoPagina}
                                    isVisible={isHovered === option}
                                  />
                                  {/* <img
                                    className="hover-image-async-select"
                                    src={imageUrl}
                                    alt={option.label.descricaoPagina}
                                  /> */}
                                </div>
                                <div>
                                  <strong id="grey-bold-async-select">
                                    {option.label.partnumber ||
                                      option.label.codimate}{' '}
                                    - {option.label.descricao}{' '}
                                  </strong>
                                  <div id="grey-italic-async-select">
                                    {option.label.descricaoPagina}{' '}
                                    <strong id="black-bold-async-select">
                                      {' '}
                                      |{' '}
                                    </strong>
                                    {option.label.fmtPaginaCatalogoNivel}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <strong id="grey-bold-async-select">
                                  {option.label.partnumber ||
                                    option.label.codimate}{' '}
                                  - {option.label.descricao}
                                  <strong id="black-bold-async-select">
                                    {' '}
                                    |{' '}
                                  </strong>
                                </strong>
                                <span id="grey-italic-async-select">
                                  {option.label.descricaoPagina}{' '}
                                  <strong id="black-bold-async-select">
                                    {' '}
                                    |{' '}
                                  </strong>
                                  {option.label.fmtPaginaCatalogoNivel}
                                </span>
                              </>
                            )}
                          </div>
                        );
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '40px',
                          borderRadius: '5px 0 0 5px',
                        }),
                        menu: (base) => ({
                          ...base,
                          backgroundColor: '#fafafa',
                          // width: 450,
                          minHeight: 550,
                        }),
                        menuList: (base) => ({
                          ...base,
                          minHeight: 500,
                          maxHeight: 550,
                        }),
                        groupHeading: (base) => ({
                          ...base,
                          color: '#525252',
                          borderBottom: '1px solid #cbd5e1',
                        }),
                        clearIndicator: (base) => ({
                          ...base,
                          '&:hover': {
                            color: '#dc2626',
                          },
                        }),
                        option: (base, state) => ({
                          ...base,
                          backgroundColor: state.isFocused
                            ? '#e5e7eb'
                            : '#f8fafc',
                          '&:hover': {
                            backgroundColor: '#e5e7eb',
                          },
                        }),
                      }}
                      width={450}
                    />
                  </Form>
                  <BtnFilter
                    title="Filtrar páginas pelo termo digitado"
                    onClick={() => {
                      handleFiltroAsyncSelect(valueAsyncSelect);
                      // setValueAsyncSelect(null);
                    }}
                  >
                    <MdSearch size={20} />
                  </BtnFilter>
                </Form.Row>
              </Container.Header.Search>
            </Container.Header>

            <Wrapper>
              {!pageMaximize && (
                <Menu>
                  {catalogo && (
                    <>
                      <Menu.Content hidden={menuHidden}>
                        <Menu.Content.TreeView>
                          <Menu.Content.Filter>
                            {catalogo.sistema && (
                              <span>
                                {catalogo.sistema.descricao}
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleFiltro(
                                      'FLT_IDS',
                                      catalogo.sistema.codiSist
                                    )
                                  }
                                >
                                  <MdClose size={12} />
                                </button>
                              </span>
                            )}

                            {catalogo.local && (
                              <span>
                                {catalogo.local.descricao}
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleFiltro(
                                      'FLT_IDL',
                                      catalogo.local.codiLoca
                                    )
                                  }
                                >
                                  <MdClose size={12} />
                                </button>
                              </span>
                            )}
                          </Menu.Content.Filter>
                          <TreeView data={catalogo} ref={refTtreeView} />
                        </Menu.Content.TreeView>

                        {/* <Menu.Content.File>
                          <h4>Anexos</h4>
                          <ul>
                            <li>
                              <a href="/#">NomeDaPeça.mp4</a>
                            </li>
                            <li>
                              <a href="/#">ManualDaPeça.pdf</a>
                            </li>
                            <li>
                              <a href="/#">DiagramaSecundario.pdf</a>
                            </li>
                          </ul>
                        </Menu.Content.File> */}
                      </Menu.Content>
                      <Menu.BtnCollapse
                        collapse={menuHidden}
                        onClick={() => setMenuHidden(!menuHidden)}
                      >
                        {menuHidden ? (
                          <MdChevronRight size={16} />
                        ) : (
                          <MdChevronLeft size={16} />
                        )}
                      </Menu.BtnCollapse>
                    </>
                  )}
                </Menu>
              )}

              <Content>
                <Content.ToolBar>
                  {catalogo && handleBreadcrumb(catalogo)}

                  {controle && (
                    <Content.ToolBar.DrawControl>
                      <button
                        type="button"
                        onClick={debounce(() => {
                          handlePageNav(-1);
                          handleScrollLinkTreeView();
                        }, 100)}
                      >
                        <MdChevronLeft size={20} />
                      </button>
                      <span>{shownPage}</span>
                      <button
                        type="button"
                        onClick={debounce(() => {
                          handlePageNav(1);
                          handleScrollLinkTreeView();
                        }, 100)}
                      >
                        <MdChevronRight size={20} />
                      </button>
                      <button ref={zoomInRef} type="button">
                        <MdZoomIn size={20} />
                      </button>
                      <button ref={zoomOutRef} type="button">
                        <MdZoomOut size={20} />
                      </button>
                      <button ref={zoomResetRef} type="button">
                        <MdYoutubeSearchedFor size={20} />
                      </button>
                      <button type="button" onClick={handleRedimensionar}>
                        {pageMaximize ? (
                          <MdFullscreenExit size={20} color="#ff6d3c" />
                        ) : (
                          <MdFullscreen size={20} />
                        )}
                      </button>
                      {/* <button type="button" onClick={handleImprimir}>
                        <MdPrint size={20} />
                      </button> */}
                      <button
                        type="button"
                        onClick={handlePDF}
                        disabled={loadingButtonPrint}
                      >
                        {loadingButtonPrint ? (
                          <GiSandsOfTime size={20} color="#ff6d3c" />
                        ) : (
                          <MdPrint size={20} />
                        )}
                      </button>
                      <button
                        type="button"
                        onClick={handleMail}
                        disabled={loadingButtonMail}
                      >
                        {loadingButtonMail ? (
                          <GiSandsOfTime size={20} color="#ff6d3c" />
                        ) : (
                          <MdEmail size={20} />
                        )}
                      </button>
                    </Content.ToolBar.DrawControl>
                  )}
                </Content.ToolBar>

                {catalogo && handleContainer(catalogo)}
              </Content>
            </Wrapper>
          </>
        )}
      </Container>
    </>
  );
}

export default Classificacao;
