import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import {
  MdImage,
  MdSchema,
  MdLink,
  MdList,
  MdModeEdit,
  MdOutlineAddPhotoAlternate,
  MdTableRows,
} from 'react-icons/md';

import { useSelector } from 'react-redux';
import TitleBar from '../../../components/TitleBar';

import Pagination from '../../../components/EditorAI/Pagination';
import { Form } from '../../../components/Form';
import InputViewer from '../../../components/Form/Input/Viewer';

import { RowMaster, Table } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';

import api from '../../../services/api';
import history from '../../../services/history';
import { CSV, opExport } from '../../../lib/csv-core';

import {
  opCatalogoTipoConversao,
  opPaginaCatalogoTipo,
  opPaginaCatalogoSituacao,
  opJob,
  opTipoAcesso,
} from '../../../lib/const';
import {
  opCatalogoSituacao,
  opIdioma,
  opTipoItemPagina,
  opTipoSequenciaPagina,
} from '../../../lib/inputOption';

import ViewerDetail from '../../../components/ViewerDetail';

import {
  CatalogStatus,
  Container,
  TableCatalog,
  Wrapper,
  MessageStatus,
} from './styles';

const getItemSituacao = (situacao) => {
  switch (situacao) {
    case 0:
      return (
        <TableCatalog.ColumnStatus status={situacao}>
          Importado
        </TableCatalog.ColumnStatus>
      );
    case 1:
      return (
        <TableCatalog.ColumnStatus status={situacao}>
          Classificado
        </TableCatalog.ColumnStatus>
      );
    case 2:
      return (
        <TableCatalog.ColumnStatus status={situacao}>
          Concluído
        </TableCatalog.ColumnStatus>
      );
    case 3:
      return (
        <TableCatalog.ColumnStatus status={situacao}>
          Editado
        </TableCatalog.ColumnStatus>
      );
    default:
      return (
        <TableCatalog.ColumnStatus status={situacao}>
          Erro
        </TableCatalog.ColumnStatus>
      );
  }
};

const getItemTipo = (tipo) => {
  switch (tipo) {
    case 0:
      return (
        <TableCatalog.ColumnType type={tipo}>
          Não Classificado
        </TableCatalog.ColumnType>
      );
    case 1:
      return (
        <TableCatalog.ColumnType type={tipo}>Desenho</TableCatalog.ColumnType>
      );
    case 2:
      return (
        <TableCatalog.ColumnType type={tipo}>Tabela</TableCatalog.ColumnType>
      );
    case 3:
      return (
        <TableCatalog.ColumnType type={tipo}>Misto</TableCatalog.ColumnType>
      );
    case 4:
      return (
        <TableCatalog.ColumnType type={tipo}>Removido</TableCatalog.ColumnType>
      );
    default:
      return (
        <TableCatalog.ColumnType type={tipo}>Erro</TableCatalog.ColumnType>
      );
  }
};

const INTERVAL = 1000 * 5; // = 5s

function CatalogoDetalhe() {
  const location = useLocation();
  const { id } = location.state;

  const { adm } = useSelector((state) => state.user.profile || {});

  const [analise, setAnalise] = useState(null);
  const [catalogo, setCatalogo] = useState(null);
  const [paginaCatalogo, setPaginaCatalogo] = useState(null);

  const [viewerDetail, setViewerDetail] = useState(null);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [paginaConcluido, paginaTotal] = useMemo(() => {
    const { analisePaginaCatalogo } = analise || {};
    const { concluido = 0, total = 0 } = analisePaginaCatalogo || {};
    return [concluido, total];
  }, [analise]);

  const carregaCatalogo = useCallback(() => {
    api.get(`catalogo/${id}`).then((response) => {
      setCatalogo(null);

      const {
        arquivo,
        nivelClassificacoes,
        series,
        empresas,
        idioma,
        iaTipoItemPagina,
        iaTipoSequenciaPagina,
      } = response.data;

      /** formata campo fabricante */
      const fmtFabricante = arquivo.fabricante && arquivo.fabricante.descricao;

      /** formata campo modelo */
      const fmtModelo = arquivo.modelos
        .map((modelo) => modelo.descricao)
        .join(', ');

      /** formata campo rotulo */
      const fmtNivelClassificacao = nivelClassificacoes.map((rotulo) => ({
        nivel: rotulo.nivel,
        [`descricao-${rotulo.nivel}`]: rotulo.descricao,
      }));

      /** formata campo número de série */
      const fmtNumeroSerie = series
        .map((serie) => serie.numeroSerie)
        .join(', ');

      const fmtEmpresaCatalogo = empresas
        .map((empresa) => empresa.descricao)
        .join(', ');

      const qtdeModelo = arquivo.modelos.length || 0;
      const qtdeNumeroSerie = series.length || 0;
      const qtdeEmpresaCatalogo = empresas.length || 0;

      /** formata campo idioma */
      const fmtIdioma = opIdioma[Number(idioma)]
        ? opIdioma[Number(idioma)].label
        : '';

      /** formata campo IA tipo item página  */
      const fmtIATipoItemPagina = opTipoItemPagina[Number(iaTipoItemPagina)]
        ? opTipoItemPagina[Number(iaTipoItemPagina)].label
        : '';

      /** formata campo IA tipo página  */
      const fmtIATipoSequenciaPagina = opTipoSequenciaPagina[
        Number(iaTipoSequenciaPagina)
      ]
        ? opTipoSequenciaPagina[Number(iaTipoSequenciaPagina)].label
        : '';

      /** carrega as informações do catálogo */
      setCatalogo({
        ...response.data,
        idioma: fmtIdioma,
        iaTipoItemPagina: fmtIATipoItemPagina,
        iaTipoSequenciaPagina: fmtIATipoSequenciaPagina,
        fabricante: fmtFabricante,
        qtdeModelo,
        modelo: fmtModelo,
        nivelClassificacao: fmtNivelClassificacao,
        numeroSerie: fmtNumeroSerie,
        qtdeNumeroSerie,
        empresaCatalogo: fmtEmpresaCatalogo,
        qtdeEmpresaCatalogo,
      });
    });
  }, [id]);

  const carregaPaginaCatalogo = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page, ...flt } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
      ...flt,
    };

    api
      .get(`catalogo/${id}/pagina`, {
        params: configFiltro,
      })
      .then((response) => {
        const {
          qtdeProcessada,
          qtdeTotal,
          processado,
          descErro,
        } = response.data.processamento;

        setPaginaCatalogo({
          paginas: response.data.paginaCatalogo,
          emProcessamento: [opJob.EM_PROCESSAMENTO, opJob.ERRO].includes(
            processado
          ),
          processado,
          qtdeProcessada,
          qtdeTotal,
          descErro,
        });

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total && paginationRef.current) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });

    api.get(`catalogo/${id}/analise`).then((response) => {
      setAnalise(response.data.analise);
    });
  }, [location, id]);

  useEffect(() => {
    carregaCatalogo();
  }, [location, id, carregaCatalogo]);

  useEffect(() => {
    let ms;

    const { processado = opJob.EM_PROCESSAMENTO } = paginaCatalogo || {};
    switch (processado) {
      case opJob.EM_PROCESSAMENTO:
      case opJob.ERRO:
        ms = setTimeout(() => {
          carregaPaginaCatalogo();
        }, INTERVAL);
        break;
      case opJob.CONCLUIDO:
        clearTimeout(ms);
        break;
      default:
        break;
    }

    return () => clearTimeout(ms);
  }, [paginaCatalogo, carregaPaginaCatalogo]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaPaginaCatalogo();
  }, [location, currentPage, carregaPaginaCatalogo]);

  const handleVisualizarPagina = useCallback(
    (e, idPagina) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        history.push('/catalogo/pagina', { id, paginaInicial: idPagina });
      }
    },
    [id]
  );

  const handleEditar = useCallback(() => {
    history.push('/catalogo/editar', { background: location, id });
  }, [location, id]);

  const handleEditorClassificar = useCallback(() => {
    const { tipoConversao } = catalogo;

    switch (Number(tipoConversao)) {
      case opCatalogoTipoConversao.INTELIGENCIA_ARTIFICIAL:
        history.push('/edicao/classificarAI', { id });
        break;
      case opCatalogoTipoConversao.MANUAL:
        history.push('/edicao/classificar', { id });
        break;
      default:
        break;
    }
  }, [id, catalogo]);

  const handleEditorClassificarReprocessar = useCallback(() => {
    api.put(`editor-thumb/${id}`).then(() => {
      carregaPaginaCatalogo();
    });
  }, [id, carregaPaginaCatalogo]);

  const handleEditorExtrair = useCallback(
    (idPagina) => {
      const { tipoConversao } = catalogo;

      api.get(`editor-extrair/${id}`).then((response) => {
        const paginaIndex = response.data.paginaCatalogo.findIndex(
          (pagina) => pagina.idPagina === idPagina
        );

        switch (Number(tipoConversao)) {
          case opCatalogoTipoConversao.INTELIGENCIA_ARTIFICIAL:
            history.push('/edicao/extrairAI', {
              id,
              paginaInicial: paginaIndex + 1,
            });
            break;
          case opCatalogoTipoConversao.MANUAL:
            history.push('/edicao/extrair', {
              id,
              paginaInicial: paginaIndex + 1,
            });
            break;
          default:
            break;
        }
      });
    },
    [id, catalogo]
  );

  const handleEditorEditar = useCallback(
    (idPagina) => {
      const { tipoConversao } = catalogo;

      api.get(`editor-editar/${id}`).then((response) => {
        const paginaIndex = response.data.paginaCatalogo.findIndex(
          (pagina) => pagina.idPagina === idPagina
        );

        switch (Number(tipoConversao)) {
          case opCatalogoTipoConversao.INTELIGENCIA_ARTIFICIAL:
            history.push('/edicao/editarAI', {
              id,
              paginaInicial: paginaIndex + 1,
            });
            break;
          case opCatalogoTipoConversao.MANUAL:
            history.push('/edicao/editar', {
              id,
              paginaInicial: paginaIndex + 1,
            });
            break;
          default:
            break;
        }
      });
    },
    [id, catalogo]
  );

  const handleEditorAssociar = useCallback(
    (idPagina) => {
      const { tipoConversao } = catalogo;

      api.get(`editor-associar/${id}`).then((response) => {
        const paginaIndex = response.data.paginaCatalogo.findIndex(
          (pagina) => pagina.idPagina === idPagina
        );

        switch (Number(tipoConversao)) {
          case opCatalogoTipoConversao.INTELIGENCIA_ARTIFICIAL:
            history.push('/edicao/associarAI', {
              id,
              paginaInicial: paginaIndex + 1,
            });
            break;
          case opCatalogoTipoConversao.MANUAL:
            history.push('/edicao/associar', {
              id,
              paginaInicial: paginaIndex + 1,
            });
            break;
          default:
            break;
        }
      });
    },
    [id, catalogo]
  );

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.CATALOGO_PAGINA, { idCatalogo: id });
  }, [id]);

  return (
    <Container>
      <TitleBar back />

      <h2>Detalhes</h2>
      {catalogo && (
        <Wrapper>
          <Form initialData={catalogo}>
            <Form.Row>
              <InputViewer id="idCatalogo" name="idCatalogo" label="Catálogo" />
              <InputViewer id="nome" name="nome" label="Classe Operacional" />
              <InputViewer id="idioma" name="idioma" label="Idioma" />
              <CatalogStatus type={catalogo.situacao}>
                {opCatalogoSituacao[catalogo.situacao]
                  ? opCatalogoSituacao[catalogo.situacao].label
                  : ''}
              </CatalogStatus>
            </Form.Row>
            <Form.Row>
              <InputViewer
                id="fabricante"
                name="fabricante"
                label="Fabricante"
              />
              <InputViewer
                id="modelo"
                name="modelo"
                label="Modelo"
                type="attach"
                attachLabel={
                  catalogo.qtdeModelo > 0 ? `(${catalogo.qtdeModelo})` : ''
                }
                fnAttach={() =>
                  setViewerDetail({
                    title: 'Lista de modelos relacionados',
                    data: catalogo.arquivo.modelos.map(
                      (modelo) => `${modelo.idModelo} - ${modelo.descricao}`
                    ),
                  })
                }
              />
              <InputViewer
                id="iaTipoItemPagina"
                name="iaTipoItemPagina"
                label="Tipo Item Página"
              />
              <InputViewer
                id="iaTipoSequenciaPagina"
                name="iaTipoSequenciaPagina"
                label="Tipo Sequência Página"
              />
            </Form.Row>
            <Form.Row>
              <InputViewer id="versao" name="versao" label="Versão" />
              <InputViewer id="ano" name="ano" label="Ano Fab." />
              <InputViewer
                id="numeroSerie"
                name="numeroSerie"
                label="Número Série"
                type="attach"
                attachLabel={
                  catalogo.qtdeNumeroSerie > 0
                    ? `(${catalogo.qtdeNumeroSerie})`
                    : ''
                }
                fnAttach={() =>
                  setViewerDetail({
                    title: 'Lista de números de série relacionados',
                    data: catalogo.series.map(
                      (numeroSerie) =>
                        `${numeroSerie.idSerie} - ${numeroSerie.numeroSerie} (${
                          numeroSerie.partnumber || '-'
                        })`
                    ),
                  })
                }
              />
              <InputViewer
                id="qtdeNivel"
                name="qtdeNivel"
                label="Qtde. Níveis"
              />
              {adm === opTipoAcesso.ADMIN_PLATAFORMA && (
                <InputViewer
                  id="empresaCatalogo"
                  name="empresaCatalogo"
                  label="Empresas"
                  type="attach"
                  attachLabel={
                    catalogo.qtdeEmpresaCatalogo > 0
                      ? `(${catalogo.qtdeEmpresaCatalogo})`
                      : ''
                  }
                  fnAttach={() =>
                    setViewerDetail({
                      title: 'Lista de empresas relacionados',
                      data: catalogo.empresas.map(
                        (empresa) =>
                          `${empresa.idEmpresa} - ${empresa.descricao}`
                      ),
                    })
                  }
                />
              )}
            </Form.Row>
            <Form.Column>
              <h3>Informações do arquivo</h3>
              <Form.Row>
                <InputViewer
                  id="arquivoUrl"
                  name="arquivo.url"
                  label="Arquivo"
                  type="link"
                />
                <InputViewer
                  id="arquivoNumeroPagina"
                  name="arquivo.numeroPagina"
                  label="Num. Páginas"
                />
              </Form.Row>
            </Form.Column>
            <Form.Column>
              <h3>Rótulo dos níveis</h3>
              <Form.Row>
                {catalogo.nivelClassificacao.map((nivel, index) => (
                  <InputViewer
                    key={`nivelClassificacao-${nivel.nivel}`}
                    id={nivel.nivel}
                    name={`nivelClassificacao[${index}].descricao-${nivel.nivel}`}
                    label={`Rótulo nível ${nivel.nivel}`}
                  />
                ))}
              </Form.Row>
            </Form.Column>
            <Form.Separate height={30} />
            <Form.Footer withStart>
              <Form.Footer.Link type="button" onClick={handleEditar}>
                <MdModeEdit size={18} /> Editar
              </Form.Footer.Link>
              <Form.Footer.Link
                type="button"
                onClick={() => history.push('/catalogo/series', { id })}
              >
                <MdList size={18} />
                Nº Série & Arranjos
              </Form.Footer.Link>
              <Form.Footer.Link
                type="button"
                onClick={() => history.push('/catalogo/itens', { id })}
              >
                <MdList size={18} /> Itens
              </Form.Footer.Link>
              <Form.Footer.Link
                type="button"
                onClick={() => history.push('/catalogo/nivel', { id })}
              >
                <MdList size={18} /> Níveis
              </Form.Footer.Link>
              {paginaCatalogo && !paginaCatalogo.emProcessamento && (
                <Form.Footer.Link
                  type="button"
                  onClick={handleEditorClassificar}
                >
                  <MdSchema size={18} /> Editor
                </Form.Footer.Link>
              )}
            </Form.Footer>
          </Form>
        </Wrapper>
      )}

      {paginaCatalogo && (
        <>
          {paginaCatalogo.emProcessamento && (
            <MessageStatus>
              {paginaCatalogo.processado === opJob.EM_PROCESSAMENTO && (
                <>
                  <MessageStatus.Title>
                    As páginas estão sendo processadas e em breve todas estarão
                    disponíveis para serem classificadas...
                  </MessageStatus.Title>
                  <MessageStatus.Title>
                    {`${paginaCatalogo.qtdeProcessada} de ${paginaCatalogo.qtdeTotal} páginas processadas`}
                  </MessageStatus.Title>
                  {paginaCatalogo.qtdeProcessada > 0 && (
                    <MessageStatus.BtnAct
                      type="button"
                      onClick={handleEditorClassificar}
                    >
                      Classificar
                    </MessageStatus.BtnAct>
                  )}
                </>
              )}

              {paginaCatalogo.processado === opJob.ERRO && (
                <>
                  <MessageStatus.Title>
                    Ocorreu um erro no processamento do catálogo:
                  </MessageStatus.Title>
                  <MessageStatus.Error>
                    {paginaCatalogo.descErro}
                  </MessageStatus.Error>
                  <MessageStatus.BtnError
                    type="button"
                    onClick={handleEditorClassificarReprocessar}
                  >
                    Tentar novamente
                  </MessageStatus.BtnError>
                </>
              )}
            </MessageStatus>
          )}

          {!paginaCatalogo.emProcessamento && (
            <>
              <TitleBar
                title="Páginas"
                subtitle={`${paginaConcluido} de ${paginaTotal} páginas concluídas`}
                width={2000}
                isSearchable
                filterSearchType="CATALOGO_PAGINA"
                onFileDownload={handleExportar}
              />

              <Table>
                <TableHeader
                  withPadding
                  titles={[
                    { fieldname: 'idPagina', title: 'Página' },
                    { fieldname: 'tipo', title: 'Tipo' },
                    { fieldname: 'situacao', title: 'Situação' },
                    { fieldname: 'ordena', title: 'Ordem' },
                    { fieldname: 'referencia', title: 'Referência' },
                    { fieldname: null, title: null },
                  ]}
                  cbSort={() => {}}
                />
                <tbody>
                  {paginaCatalogo.paginas.map((pagina) => (
                    <RowMaster
                      key={pagina.idPagina}
                      onClick={(e) =>
                        handleVisualizarPagina(e, pagina.idPagina)
                      }
                    >
                      <td>{`Página #${pagina.idPagina}`}</td>
                      <td>{getItemTipo(pagina.tipo)}</td>
                      <td>{getItemSituacao(pagina.situacao)}</td>
                      <td>{pagina.ordena}</td>
                      <td>
                        {pagina.referencia && (
                          <TableCatalog.Row>
                            <MdLink size={18} />
                            {pagina.referencia}
                          </TableCatalog.Row>
                        )}
                      </td>
                      <td>
                        <TableCatalog.Menu>
                          {[
                            opPaginaCatalogoTipo.TABELA,
                            opPaginaCatalogoTipo.MISTO,
                          ].includes(pagina.tipo) && (
                            <TableCatalog.Menu.BtnExtract
                              type="button"
                              title="Navegar para o processo de Extração"
                              onClick={() =>
                                handleEditorExtrair(pagina.idPagina)
                              }
                            >
                              <MdTableRows size={25} />
                            </TableCatalog.Menu.BtnExtract>
                          )}

                          {[
                            opPaginaCatalogoTipo.DESENHO,
                            opPaginaCatalogoTipo.MISTO,
                          ].includes(pagina.tipo) && (
                            <TableCatalog.Menu.BtnEdit
                              type="button"
                              title="Navegar para o processo de Edição"
                              onClick={() =>
                                handleEditorEditar(pagina.idPagina)
                              }
                            >
                              <MdImage size={25} />
                            </TableCatalog.Menu.BtnEdit>
                          )}

                          {[
                            opPaginaCatalogoTipo.DESENHO,
                            opPaginaCatalogoTipo.MISTO,
                          ].includes(pagina.tipo) &&
                            [
                              opPaginaCatalogoSituacao.DESENHO_EDITADO,
                              opPaginaCatalogoSituacao.CONCLUIDO,
                            ].includes(pagina.situacao) && (
                              <TableCatalog.Menu.BtnEdit
                                type="button"
                                title="Navegar para o processo de Mapeamento"
                                onClick={() =>
                                  handleEditorAssociar(pagina.idPagina)
                                }
                              >
                                <MdOutlineAddPhotoAlternate size={25} />
                              </TableCatalog.Menu.BtnEdit>
                            )}
                        </TableCatalog.Menu>
                      </td>
                    </RowMaster>
                  ))}
                </tbody>
              </Table>
              <Pagination
                ref={paginationRef}
                pageCount={totalPage}
                initialData={1}
                onPageChange={(page) => {
                  setCurrentPage(page);
                }}
              />
            </>
          )}
        </>
      )}

      {viewerDetail && (
        <ViewerDetail handleClose={() => setViewerDetail(null)}>
          <h2>{viewerDetail.title}</h2>
          <ul>
            {viewerDetail.data.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </ViewerDetail>
      )}
    </Container>
  );
}

export { CatalogoDetalhe };
